// src/features/convert/convertSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, store } from '../../store'; // Import the AppThunk type if defined
import {generateRandomName, generateRandomColor, colorToCssString, getServerUrl, isNumber, MessageObject, OrderFillTransaction, removeExtension, TradeOpen, UserProfile, youtubeConvertAction, delay} from '../util'
import axios,{AxiosError, AxiosResponse} from 'axios';
import { AuthState, logOutAction } from '../login/login';
import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import { displayconn, getConnection, startConnection } from '../../../services/hubConnectionService';
import { signalRService } from "../../../services/signalrService";

//import * as _ from "lodash";
const _ = require('lodash');


export interface IChatHubState {
    conn: HubConnection|null;
    username: string;
    chatroom:string;
    messages2: {[key: string]: MessageObject}, //MessageObject[];
    messages: {[key: string]:{[key: string]: MessageObject}}, //MessageObject[];
    open_add_contact_modal: boolean;
    connections: {[key: string]: HubConnection},
    rooms:Array<string>,
    connectedRooms:string[],
    usersInRoom: {[key: string]: string},

}

const initialState: IChatHubState = {
    conn: null,
    username:generateRandomName(),
    chatroom:'Room1',
    messages: {['']:{}},//[],
    messages2: {},
    open_add_contact_modal: false,
    connections: {},
    rooms:["Room1","Room2"],
    connectedRooms:[],
    usersInRoom: {},

};

const chathubSlice = createSlice({
    name: 'chathub',
    initialState,
    reducers: {

      setConnectionAction: (state,action: PayloadAction<HubConnection>) => {
        state.conn=action.payload
      },
      UpdateUserNameAction: (state, action: PayloadAction<string>) => {
        state.username = action.payload;

        state.usersInRoom[action.payload] = colorToCssString(generateRandomColor());
        //console.log('UpdateUserNameAction called with: ======__________888888_____________===>', action.payload, state.usersInRoom)
      },
      UpdateChatRoomAction: (state, action: PayloadAction<string>) => {
        //if(isValidYouTubeUrl(action.payload))
        state.chatroom = action.payload;
      },
      receiveSpecificMessagesAction: (state, action: PayloadAction<MessageObject>) => {
        console.log('receiveSpecificMessagesAction ======__________888888_____________===>===>', action.payload)
        if (state.usersInRoom.hasOwnProperty(action.payload.destinatorname)) {
            //console.log(`User with ID ${action.payload.destinatorname} already exists.`);
        } else {
            state.usersInRoom[action.payload.destinatorname] = colorToCssString(generateRandomColor());
            //console.log(`User ${action.payload.destinatorname} added to the room.`);
        }

        if (state.usersInRoom.hasOwnProperty(action.payload.sendername)) {
            //console.log(`User with ID ${action.payload.sendername} already exists.`);
        } else {
            state.usersInRoom[action.payload.sendername] = colorToCssString(generateRandomColor());
            //console.log(`User ${action.payload.sendername} added to the room.`);
        }

      },
      showHideModalAction: (state) => {
        state.open_add_contact_modal = !state.open_add_contact_modal;

      },
      joinChatRoomSuccessAction: (state) => {

      },
      addConnection: (state, action) => {
        //console.log('addConnections is called with payload: ', action.payload)
        const { roomNumber, hubConnection } = action.payload;
        state.connections[roomNumber] = hubConnection;
      },
      addConnectedRoom: (state, action) => {
        const roomNumber = action.payload;
        if (!state.connectedRooms.includes(roomNumber)) {
          state.connectedRooms.push(roomNumber);
        }
    },

    receiveMessagesFromUserAction: (state, action: PayloadAction<MessageObject>) => {
        console.log('receiveMessagesFromUserAction=========__________888888_____________===>===>>', action.payload)
        //var msgw = JSON.stringify(action.payload ) as unknown as MessageObject
        //var obj = JSON.parse(JSON.stringify(action.payload )) as MessageObject

        if (!state.messages.hasOwnProperty(state.chatroom)) {
            state.messages[state.chatroom]={}
        }
        if (state.messages[state.chatroom].hasOwnProperty(action.payload.id)) {
            console.log(`Message with ID ${action.payload.id} already exists.`);
            //alert('message already received: id: '+action.payload.id +'- sender'+ action.payload.sendername
            //+'- receiver: '+ action.payload.destinatorname +'- message: '+ action.payload.message)
            displayconn()
        } else {
            state.messages[action.payload.destinatorname][action.payload.id] = action.payload;
            //console.log(`User ${action.payload.destinatorname} added to the room.`);
        }
    
        

        if (state.usersInRoom.hasOwnProperty(action.payload.destinatorname)) {
            //console.log(`User with ID ${action.payload.destinatorname} already exists.`);
        } else {
            state.usersInRoom[action.payload.destinatorname] = colorToCssString(generateRandomColor());
            //console.log(`User ${action.payload.destinatorname} added to the room.`);
        }
        if (state.usersInRoom.hasOwnProperty(action.payload.sendername)) {
            //console.log(`User with ID ${action.payload.sendername} already exists.`);
        } else {
            state.usersInRoom[action.payload.sendername] = colorToCssString(generateRandomColor());
            //console.log(`User ${action.payload.sendername} added to the room.`);
        }

      },

    }
});

export const {
    setConnectionAction, UpdateUserNameAction, UpdateChatRoomAction,
    receiveSpecificMessagesAction, showHideModalAction, joinChatRoomSuccessAction, 
    addConnection,addConnectedRoom,receiveMessagesFromUserAction,
    
    } = chathubSlice.actions;


    export function setConnection(conn:HubConnection) {
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; chathub: IChatHubState  }
        ) => {
          const state = getState().chathub as IChatHubState;
          dispatch(setConnectionAction(conn));
      
        };
      }

    export function joinChatRoom() {
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; chathub: IChatHubState  }
    ) => {
        const state = getState().chathub as IChatHubState;
    
        try {
            let username:string= state.username
            let chatroom:string = state.chatroom
            //alert("{username, chatroom} 0 -|"+username+"|-|" +chatroom+"|")
            //console.log('joinChatRoom called with arguments:',username, chatroom)
            //console.log('getConnection from reducer called for room:===>', state.chatroom)


            /*
            const conn = new HubConnectionBuilder()
            .withUrl(getURl+ "chat")
            .configureLogging(LogLevel.Information)
            .build();
            await conn.start();
            await conn.invoke("JoinSpecificChatRoom",{username, chatroom})
            */

            
            let  connection = getConnection(state.chatroom);
            console.log('connection from reducer:===>', connection, 'for room: ', state.chatroom)
            if (connection) {
                console.log('connection.state:{}{}{}{}==>',connection.state)
                if(!(connection.state=== HubConnectionState.Connected)){
                    //delay(1000);
                    //await connection.start();
                    console.log('connection.state:{}{}{}{} 2==>',connection.state)
                }
                console.log('connection.state:{}{}{}{} 4==>',connection.state)
                if(connection.state=== HubConnectionState.Connected){
                    console.log('connection.state:{}{}{}{} 6==>',connection.state)
                    //set up handler
                    connection.on("JoinSpecificChatRoom", (username,msg)=>{
                        console.log("JoinSpecificChatRoom msg 0: ",msg, ' by: ', username)
                        var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                            sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                        dispatch(receiveSpecificMessagesAction(ww))
                    })
                    connection.on("ReceiveMessage", (username,msg,uuid)=>{
                        console.log("msg ReceiveMessage received 2 from server=======kadandsnksjndfjnsfknfkjsnnfnwwjenw>>>>>>: ",username,msg,uuid)
                        var ww:MessageObject={id:uuid, 
                            sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                        dispatch(receiveMessagesFromUserAction(ww))
                    })
                    connection.on("ReceiveSpecificMessage", (username,msg)=>{
                        console.log("msg ReceiveSpecificMessage Called Hugues: ",msg, ' by: ', username)
                        var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                            sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                        dispatch(receiveSpecificMessagesAction(ww))
                    })
                    
                    //if(!_.includes(state.connectedRooms,chatroom)){
                    //alert("{username, chatroom} -|"+username+"|-|" +chatroom+"|")
                    await connection.invoke("JoinSpecificChatRoom",{username, chatroom})
                    //}
                    connection.on('ReceiveMessage', (message) => {
                    console.log(`Message received in room ${state.chatroom}:`, message);
                });
                }else{
                    console.log('connection state is still NOT CONNECTED__________888888_____________===>===>')
                }
            }

        }catch (e){
        console.log(e)
        }
        
    
    };
    }
      
      
    export function setUserName(val: string) {
        console.log("setuser called ",val)
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; modals: IChatHubState }
        ) => {

            const state = getState().modals as IChatHubState;
            dispatch( UpdateUserNameAction(val));
        };
    }

    export function setUserChatRoom(val: string) {
        console.log("setUserChatRoom called ",val)
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; chathub: IChatHubState  }
        ) => {
            const state = getState().chathub as IChatHubState;
            dispatch( UpdateChatRoomAction(val));
            let usr = state.username

            try{
                let  connection = getConnection(val);
                console.log('connection from reducer:===>', connection, 'for room: ', val)
                if (connection) {
                    if(!(connection.state=== HubConnectionState.Connected) && !(connection.state=== HubConnectionState.Connecting)){
                        await connection.start();
                    }
                    //alert("setUserChatRoom 777"+ usr + val )
                    await connection.invoke("JoinSpecificChatRoom",{usr , val})
                }
            }catch{

            }


        };
    }

    export function showHideModal() {
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; chathub: IChatHubState  }
        ) => {
            const state = getState().chathub as IChatHubState;
            dispatch( showHideModalAction());
        
        };
    }

    export function initializeConnection() {
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; chathub: IChatHubState  }
        ) => {
            const state = getState().chathub as IChatHubState;
            state.rooms?.forEach(async (roomNumber) => {
                console.log('initializeConnection called for: ',roomNumber)
                startConnection(roomNumber).then(response=>{

                    console.log('startConnection completed for ', roomNumber)
                    dispatch(addConnectedRoom(roomNumber));
                    return response
                }
                );

                try{
                    let  connection = getConnection(roomNumber);
                    let usr = state.username
                    console.log('connection from reducer:===>', connection, 'for room: ', roomNumber)
                    if (connection) {
                        if(!(connection.state=== HubConnectionState.Connected) && !(connection.state=== HubConnectionState.Connecting)){
                            await connection.start();
                        }

                        //alert("setUserChatRoom 8888"+ usr + roomNumber )
                        await connection.invoke("JoinSpecificChatRoom",{usr , roomNumber})

                    }
                    setUserChatRoom(roomNumber)
                    joinChatRoom()
                }catch{
    
                }



                /*


                try {
                    let username:string= state.username
                    let chatroom:string = roomNumber
                    let  connection = getConnection(roomNumber);
                    console.log('connection from reducer:===>', connection, 'for room: ', roomNumber)
                    if (connection) {
                        //console.log('connection.state:{}{}{}{}==>',connection.state)
                        if(!(connection.state=== HubConnectionState.Connected)){
                            await connection.start();
                            console.log('connection.state:{}{}{}{} 2==>',connection.state)
                        }
                        console.log('connection.state:{}{}{}{} 4==>',connection.state)
                        if(connection.state=== HubConnectionState.Connected){
                            console.log('connection.state:{}{}{}{} 6==>',connection.state)
                            //set up handler
                            connection.on("JoinSpecificChatRoom", (username,msg)=>{
                                console.log("JoinSpecificChatRoom msg 0: ",msg, ' by: ', username)
                                var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                                    sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                                dispatch(receiveSpecificMessagesAction(ww))
                            })
                            connection.on("ReceiveMessage", (username,msg,uuid)=>{
                                console.log("msg ReceiveMessage received from server=======kadandsnksjndfjnsfknfkjsnnfnwwjenw>>>>>>: ",username,msg,uuid)
                                var ww:MessageObject={id:uuid, 
                                    sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                                dispatch(receiveMessagesFromUserAction(ww))
                            })
                            connection.on("ReceiveSpecificMessage", (username,msg)=>{
                                console.log("msg ReceiveSpecificMessage Called Hugues: ",msg, ' by: ', username)
                                var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                                    sendername:username, destinatorname:state.chatroom, message:msg} as MessageObject
                                dispatch(receiveSpecificMessagesAction(ww))
                            })
                            
                            //if(!_.includes(state.connectedRooms,chatroom)){
                            //await connection.invoke("JoinSpecificChatRoom",{username, chatroom})
                            //}
                            //connection.on('ReceiveMessage', (message) => {
                            //    console.log(`Message received in room ${state.chatroom}:`, message);
                            //}
                        //);
                        }else{
                            console.log('connection state is still NOT CONNECTED__________888888_____________===>===>')
                        }
                    }
        
                }catch (e){
                console.log(e)
                }
        
                

                */





                
              });    
        };
    }

    export const initializeConnection2 = (): AppThunk => async (dispatch) => {
        await signalRService.createConnection('Room1'); // Creating a default connection, if necessary
      };



    export function sendMessage(message:string) {
        return async (
            dispatch: (arg0: any) => void,
            getState: () => { (): any; new (): any; chathub: IChatHubState  }
        ) => {
            const state = getState().chathub as IChatHubState;
        
            try {
                console.log('sendMessage called with message:',message,'in room: ', state.chatroom)
                let roomNumber = state.chatroom;
                console.log('joinChatRoom3 called')
                const connection = await signalRService.startConnection(roomNumber);
                signalRService.displayConnections()
                if (connection) {
                    console.log("sendMessage called found. ============++++++++++++++>>>>>>>>>>>>", connection);
                    await connection.invoke("JoinSpecificChatRoom", { "UserName": state.username, "ChatRoom": roomNumber });
                    dispatch(UpdateChatRoomAction(roomNumber));
                    let usr= state.username
                    let room = state.chatroom
                    await connection.invoke("MessageHandler",{"UserName":usr, "ChatRoom":room, "Message":message});
                }
                /*
                let  connection = getConnection(state.chatroom);
                if (connection) {
                    if(!(connection.state=== HubConnectionState.Connected) && !(connection.state=== HubConnectionState.Connecting)){
                        await connection.start();
                    }
                    if(connection.state=== HubConnectionState.Connected){
                        console.log('connection.state:{}{}{}{} 6==>',connection.state)

                        let usr= state.username
                        let room = state.chatroom
                        await connection.invoke("MessageHandler",{"UserName":usr, "ChatRoom":room, "Message":message});
                    }
                }
                */

            }catch (e){
            console.log(e)
            }
            
        
        };
        }
            
        export function joinChatRoomInit(room:string) {
            return async (
                dispatch: (arg0: any) => void,
                getState: () => { (): any; new (): any; chathub: IChatHubState  }
            ) => {
                const state = getState().chathub as IChatHubState;
                console.log('joinChatRoomInit========================>',room)
                try {
                    let username:string= state.username
                    let chatroom:string = room //state..
                    //("{username, chatroom} 0 -|"+username+"|-|" +chatroom+"|")

                    let  connection = getConnection(chatroom);
                    console.log('connection from reducer:===>', connection, 'for room: ', chatroom)
                    if (connection) {
                        console.log('connection.state:{}{}{}{}==>',connection.state)
                        if(!(connection.state=== HubConnectionState.Connected)){
                            console.log('connection.state:{}{}{}{} 2==>',connection.state)
                            //await connection.start();
                            
                        }
                        console.log('connection.state:{}{}{}{} 4==>',connection.state)
                        if(connection.state=== HubConnectionState.Connected){
                            console.log('connection.state:{}{}{}{} 6==>',connection.state)
                            //set up handler
                            connection.on("JoinSpecificChatRoom", (username,msg)=>{
                                console.log("JoinSpecificChatRoom msg 0: ",msg, ' by: ', username)
                                var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                                    sendername:username, destinatorname:chatroom, message:msg} as MessageObject
                                dispatch(receiveSpecificMessagesAction(ww))
                            })
                            connection.on("ReceiveMessage", (username,msg,uuid)=>{
                                console.log("msg ReceiveMessage received 2 from server=======kadandsnksjndfjnsfknfkjsnnfnwwjenw>>>>>>: ",username,msg,uuid)
                                var ww:MessageObject={id:uuid, 
                                    sendername:username, destinatorname:chatroom, message:msg} as MessageObject
                                dispatch(receiveMessagesFromUserAction(ww))
                            })
                            connection.on("ReceiveSpecificMessage", (username,msg)=>{
                                console.log("msg ReceiveSpecificMessage Called Hugues: ",msg, ' by: ', username)
                                var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                                    sendername:username, destinatorname:chatroom, message:msg} as MessageObject
                                dispatch(receiveSpecificMessagesAction(ww))
                            })
                            
                            //if(!_.includes(state.connectedRooms,chatroom)){
                            //alert("{username, chatroom} -|"+username+"|-|" +chatroom+"|")
                            await connection.invoke("JoinSpecificChatRoom",{"username":username, "chatroom":chatroom})
                            //}
                            connection.on('ReceiveMessage', (message) => {
                            console.log(`Message received in room ${chatroom}:`, message);
                        });
                        }else{
                            console.log('connection state is still NOT CONNECTED__________888888_____________===>===>')
                        }
                    }else{
                        console.log('=================>, connection is null',connection)
                        displayconn()
                    }
        
                }catch (e){
                console.log(e)
                }
                
            
            };
            }

            export const joinChatRoom3 = (roomNumber: string): AppThunk => async (dispatch, getState) => {
                const state = getState();
                console.log('joinChatRoom3 called')
                const connection = await signalRService.startConnection(roomNumber);
                signalRService.displayConnections()
                if (connection) {
                    console.log("joinChatRoom3 connection found. ============++++++++++++++>>>>>>>>>>>>", connection);
                    await connection.invoke("JoinSpecificChatRoom", { "UserName": state.chathub.username, "ChatRoom": roomNumber });
                    dispatch(UpdateChatRoomAction(roomNumber));

                  connection.on("JoinSpecificChatRoom", (username,msg)=>{
                    console.log("JoinSpecificChatRoom msg 0: ",msg, ' by: ', username)
                    var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                        sendername:username, destinatorname:roomNumber, message:msg} as MessageObject
                    dispatch(receiveSpecificMessagesAction(ww))
                  })
                  connection.on("ReceiveMessage", (username,msg,uuid)=>{
                    console.log("msg ReceiveMessage received 2 from server=======kadandsnksjndfjnsfknfkjsnnfnwwjenw>>>>>>: ",username,msg,uuid)
                    var ww:MessageObject={id:uuid, 
                        sendername:username, destinatorname:roomNumber, message:msg} as MessageObject
                    dispatch(receiveMessagesFromUserAction(ww))
                  })
                  connection.on("ReceiveSpecificMessage", (username,msg)=>{
                    console.log("msg ReceiveSpecificMessage Called Hugues: ",msg, ' by: ', username)
                    var ww:MessageObject={id:generateRandomName()+generateRandomName(), 
                        sendername:username, destinatorname:roomNumber, message:msg} as MessageObject
                    dispatch(receiveSpecificMessagesAction(ww))
                  })
                
                    //if(!_.includes(state.connectedRooms,chatroom)){
                    //alert("{username, chatroom} -|"+username+"|-|" +chatroom+"|")
                    await connection.invoke("JoinSpecificChatRoom",{"username": state.chathub.username, "chatroom":roomNumber})
                    //}
                    connection.on('ReceiveMessage', (message) => {
                    console.log(`Message received in room ${roomNumber}:`, message);
                    });

                }
              };
 
export default chathubSlice.reducer;


export class ReceivedMessage {
    constructor(public username: string, public message: string) {
      this.username=username;
      this.message= message;
  }
}
