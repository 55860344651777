// src/app/store.ts
import { configureStore, ThunkAction, Action, combineReducers  } from '@reduxjs/toolkit';
import modalReducer from './modules/modal/modalSlice';
import mentalCalcReducer from './modules/mentalCalc/mentalCalcSlice';
import authReducer from './modules/login/login';
import oandaReducer from './modules/oanda/oanda';
import homeReducer from './modules/homepage/homepage';
import convertReducer from './modules/convert/convert';
import chathubReducer from './modules/chathub/chathub';
import mktdataReducer from './modules/mkt_data/mkt_data';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore } from 'redux-persist';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';


const appReducer = combineReducers({
  modal: modalReducer,
  mentalCalc: mentalCalcReducer,
  auth: authReducer,
  homepage: homeReducer,
  oanda: oandaReducer,
  convert: convertReducer,
  chathub: chathubReducer,
  mktdata: mktdataReducer,
});

const rootReducer = (state:any, action:any) => {
  if (action.type === 'USER_LOGOUT') {
    //alert('USER_LOGOUT called')
    storage.removeItem('persist:root'); // This line directly interacts with the browser's localStorage API.
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'mentalCalc','homepage','oanda','convert','chathub'], // specify which reducers should be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        //serializableCheck: false, // added this to remove error on object in redux 052724
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
// Define the RootState type based on the state shape of the store
export type RootState = ReturnType<typeof store.getState>;

// Define the AppDispatch type based on the dispatch function of the store
export type AppDispatch = typeof store.dispatch;

// Define the AppThunk type using ThunkAction
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;










/*
export const store = configureStore({
  reducer: {
    modal: modalReducer,
    mentalCalc: mentalCalcReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
});
*/