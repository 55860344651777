import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import * as _ from 'lodash';
import { getServerUrl } from "../redux/modules/util";

type ConnectionDictionary = Record<string, HubConnection | null>;

const connections: ConnectionDictionary = {};

export const displayConnections = () => {
    console.log("Connections: ", connections);
};

export const getConnection = (roomNumber: string): HubConnection | null => {
    console.log('getConnection called with parameter: ', roomNumber, ' available connections: ', connections);
    return connections[roomNumber] || null;
};

export const createConnection = async (roomNumber: string): Promise<HubConnection> => {
    console.log('createConnection called with room: ', roomNumber);
    const baseurl = getServerUrl();

    if (!connections[roomNumber]) {
        console.log('Creating new connection for room: ', roomNumber);
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseurl}chat`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        await connection.start();

        if (connection.connectionId) {
            await connection.invoke("JoinSpecificChatRoom", { "UserName": "admin", "ChatRoom": roomNumber });
        }

        connections[roomNumber] = connection;
    }

    return connections[roomNumber]!;
};

export const startConnection = async (roomNumber: string): Promise<HubConnection | null> => {
    console.log('startConnection called with ', roomNumber);
    const connection = await createConnection(roomNumber);

    if (connection.state === HubConnectionState.Disconnected) {
        await connection.start();
        console.log('startConnection called with ', roomNumber, connection.state);
    }

    // Wait for the connection state to be connected or timeout after 30 seconds
    const connectionPromise = new Promise<HubConnection | null>((resolve) => {
        let timeoutId: NodeJS.Timeout;

        const checkConnectionState = () => {
            if (connection.state === HubConnectionState.Connected) {
                clearTimeout(timeoutId);
                resolve(connection);
            }
        };

        // Check the connection state every second
        const intervalId = setInterval(checkConnectionState, 1000);

        // Set a timeout of 30 seconds
        timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            resolve(null);
        }, 30000);

        checkConnectionState();
    });

    return connectionPromise;
};

export function displayconn(){
  console.log("myMapmyMapmyMapmyMapmyMapmyMap1: =>",connections);
}