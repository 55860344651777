import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserAnswer, handleGuess, settimerlength, startTimer, handleTimerComplete, getNewProblem, initiateNewProblem, getAllTimeScore,  } from '../../redux/modules/mentalCalc/mentalCalcSlice';
import React, { useState, useRef, useEffect, LegacyRef, useCallback } from 'react';
import { Container, Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {MentalCalcState} from '../../redux/modules/mentalCalc/mentalCalcSlice'
import { AppThunk } from '../../redux/store'; 
import ReactDOM from 'react-dom';
import { AuthState } from '../../redux/modules/login/login';
import Header from '../header/header';

interface IProps {
    rstate: AuthState;
}


interface IActions {
    setUserAnswer: typeof setUserAnswer;
    handleGuess: typeof handleGuess;
    settimerlength: typeof settimerlength;
    startTimer: typeof startTimer;
    handleTimerComplete: typeof handleTimerComplete;
    getNewProblem: typeof getNewProblem;
    getAllTimeScore: typeof getAllTimeScore;

  }

type Props = IActions&IProps;

const UserComp: React.FC<Props> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setUserAnswer(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.handleGuess();
    };
    const handleTimerStart = () => {
        props.startTimer()
    };

    const setInputFocus = () => {
        inputRef.current?.focus();
      };

    const dispatch = useDispatch();

    useEffect(() => {
        //alert("initial run");  // Example of using a prop
        //dispatch(initiateNewProblem() );
        props.getAllTimeScore();
      }, [dispatch]);  // Include all dependencies in the array

      console.log('props.rstate', props.rstate)
      return (
        <>
            <Header title="Filled Order" />
            <div style={{
                backgroundColor: '#f8f9fa', 
                padding: '20px', 
                borderRadius: '5px', 
                width: '60%', 
                margin: '20px auto', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'left'
            }}>
            {/* <h2 style={{ color: '#495057', marginBottom: '15px', textAlign: 'center' }}>User Details</h2> */}
            <div style={{
                backgroundColor: '#fff',
                padding: '15px',
                borderColor: '#ced4da',
                borderRadius: '5px',
                color: '#495057',
                boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.05)',
                fontSize: '16px'
            }}>
                {props.rstate.user && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <strong>Name:</strong>
                    <span style={{ color: '#17a2b8', flexShrink: 1 }}>{props.rstate.user}</span>
                </div>
                )}
                {props.rstate.email && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <strong>Email:</strong>
                    <span style={{ color: '#17a2b8', flexShrink: 1 }}>{props.rstate.email}</span>
                </div>
                )}
                {/* {props.rstate.usertoken && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', overflowWrap: 'break-word' }}>
                    <strong>Token:</strong>
                    <span style={{ color: '#17a2b8', flexShrink: 1 }}>{props.rstate.usertoken}</span>
                </div>
                )} */}
            </div>
            </div>
        </>



    );
    
};

const mapStateToProps = (state: RootState): any => ({
    rstate: state.auth,
});

const mapDispatchToProps: IActions = {
    setUserAnswer,
    handleGuess,
    settimerlength,
    startTimer,
    handleTimerComplete,
    getNewProblem,
    getAllTimeScore,

};

export default connect(mapStateToProps, mapDispatchToProps)(UserComp);


