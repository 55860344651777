import React, { useState, useEffect, useRef } from 'react';
import ChatBubble from './chatBubble';
import ChatRoomList from './chatRoomList';
import './stylesroom2.css';
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { IChatHubState, initializeConnection, joinChatRoom, setUserChatRoom, 
  setUserName,sendMessage, joinChatRoomInit, joinChatRoom3, initializeConnection2 } from "../../redux/modules/chathub/chathub";
import { MessageObject } from '../../redux/modules/util';


interface IProps {
  btnState: IChatHubState;
}

interface IActions {
  setUserName: typeof setUserName;
  setUserChatRoom: typeof setUserChatRoom;
  joinChatRoom: typeof joinChatRoom;
  initializeConnection: typeof initializeConnection;
  sendMessage: typeof sendMessage;
  joinChatRoomInit: typeof joinChatRoomInit;
  joinChatRoom3: typeof joinChatRoom3;
  initializeConnection2: typeof initializeConnection2;
  
}


interface Message {
  id: number;
  user: string;
  text: string;
}

interface ChatRoomData {
  name: string;
  messages: Message[];
  users: string[];
}

type Props = IActions&IProps;

const ChatRoom: React.FC<Props> = (props) => {

  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  /*
  useEffect(() => {
    inputRef.current?.focus();
    console.log('props.btnState.rooms',props.btnState.rooms)
    props.setUserChatRoom(props.btnState?.chatroom)

    props.setUserChatRoom("Room1")
    props.initializeConnection()
    
    //props.btnState.rooms?.forEach((roomNumber) => {
    //  props.joinChatRoomInit(roomNumber);
    //});
    props.joinChatRoom()
    scrollToBottom();


  }, []);*/


  useEffect(() => {
    inputRef.current?.focus();
    const initializeAndJoinRoom = async () => {
      await props.initializeConnection2();
      props.setUserChatRoom(props.btnState.chatroom);
      await props.joinChatRoom3(props.btnState.chatroom);
    };
    initializeAndJoinRoom();
    scrollToBottom();
  }, []);

  useEffect(() => {
    props.joinChatRoom3(props.btnState.chatroom);
}, [props.btnState.messages]);

  useEffect(() => {
    scrollToBottom();
}, [props.btnState.messages]);

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      props.sendMessage(inputValue)
    //   const newMessage: Message = { id: messageId.current++, user: currentUser, text: inputValue };
    //   setRooms((prevRooms) =>
    //     prevRooms.map((room) =>
    //       room.name === currentRoom ? { ...room, messages: [...room.messages, newMessage] } : room
    //     )
    //   );
      setInputValue('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSendMessage();
      scrollToBottom();
    }
  };

  const handleRoomSelect = (room: string) => {
    //setCurrentRoom(room);
    props.setUserChatRoom(room)
    //alert(room);
    /*e =>{
        e.preventDefault();
        props.joinChatRoom(props.btnState.username,props.btnState.chatroom)
     }*/
     console.log('currentUser,currentRoomkkkkkkkkkkkkkkkkkkkkkkkkkkk',props.btnState.username,props.btnState.chatroom,props.joinChatRoom)
     //props.setUserChatRoom(currentRoom)
     props.joinChatRoom3(room)
  };

  const handleCreateRoom = () => {
    const newRoomName = prompt('Enter new room name:');
    if (newRoomName && newRoomName.trim() !== '') {
      ///setRooms([...rooms, { name: newRoomName, messages: [], users: [currentUser] }]);
    }
  };

  const handleAddUser = (room: string) => {
    const newUser = prompt('Enter new user name:');
    if (newUser && newUser.trim() !== '') {
      //setRooms((prevRooms) =>
      //  prevRooms.map((r) => (r.name === room ? { ...r, users: [...r.users, newUser] } : r))
      //);
    }
  };

  //const currentRoomData = rooms.find((room) => room.name === currentRoom);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({  behavior: 'smooth'  });
    //window.scrollBy(0,100);
    };
    return (
        <div className="chat-room-container">
          <ChatRoomList
            rooms={props.btnState.rooms.map((room) => room)}
            currentRoom={props.btnState.chatroom}
            onRoomSelect={handleRoomSelect}
            onCreateRoom={handleCreateRoom}
            onAddUser={handleAddUser}
          />
          <div className="chat-room">
            <div className="messages">
            {props.btnState.messages?.[props.btnState.chatroom] &&
            Object.entries(props.btnState.messages[props.btnState.chatroom])?.map(([key, message]) => {
                //console.log("messages map: ", (message as MessageObject).id);
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        message.sendername === props.btnState.username
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <ChatBubble
                      key={message.id}
                      message={message}
                      currentUser={props.btnState.username}
                      userColors={props.btnState.usersInRoom}
                    />
                  </div>
                );
              })}
              <div style={{marginBottom:'10px'}} />
              <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
            
          </div>
        </div>
      );
//   return (
    
//     <div className="chat-room-container">
//         <div className="chat-room-list">
//         <ChatRoomList
//             rooms={rooms.map((room) => room.name)}
//             currentRoom={currentRoom}
//             onRoomSelect={handleRoomSelect}
//             onCreateRoom={handleCreateRoom}
//             onAddUser={handleAddUser}
//         />
//         </div>
        
//       <div className="chat-room">
//         <div className="messages">
//           {props.btnState.messages?.map((message) => {
//             console.log("messages map: ", (message as MessageObject).id);
//             return <div style={{display: 'flex', justifyContent: (message.sendername === props.btnState.username)?'flex-end':'flex-start'}}>
//                 <ChatBubble
//                     key={message.id}
//                     message={message}
//                     currentUser={props.btnState.username}
//                     userColors={props.btnState.usersInRoom}//{userColors.current}
              
//                 />
//             </div>
//           })}
//           <div ref={messagesEndRef} />
//         </div>
//         <div className="input-container">
//           <input
//             ref={inputRef}
//             type="text"
//             value={inputValue}
//             onChange={handleInputChange}
//             onKeyPress={handleKeyPress}
//             placeholder="Type your message..."
//           />
//           <button onClick={handleSendMessage}>Send</button>
//         </div>
//       </div>
//     </div>
//   );
};

//export default ChatRoom;

const mapStateToProps = (state: RootState): any => ({
    btnState: state.chathub,
});

const mapDispatchToProps: IActions = {
    setUserName,
    setUserChatRoom,
    joinChatRoom,
    initializeConnection,
    sendMessage,
    joinChatRoomInit,
    joinChatRoom3,
    initializeConnection2

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
/**/

