

//Functions
export function getServerUrl() {
    var response:string = "https://localhost:5201/";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      response = process.env.REACT_APP_API_URL_DEV??'' //"https://localhost:4557/"; //
    } else {
      response = process.env.REACT_APP_API_URL_PROD??'';
    }
    // console.warn("sever : ", response);
    return response;
  }

export function handleErrors(response: any) {
    // console.warn("check error", moment().format("LLLL"), response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }





//Classes

export class TrendOverTime {
    epochdate: number;
    count: number;
    isPartial: boolean;
    date: Date;
    constructor(
      epochdate: number,
      count: number,
      date: Date,
      isPartial: boolean
    ) {
      this.epochdate = epochdate;
      this.count = count;
      this.date = date;
      this.isPartial = isPartial;
    }
  }
  export class TrendRegion {
    geoName: number;
    count: number;
  
    constructor(geoName: number, count: number) {
      this.geoName = geoName;
      this.count = count;
    }
  }
  export class TrendSuggestion {
    index: number;
    mid: string;
    title: string;
    type: string;
    constructor(index: number, mid: string, title: string, type: string) {
      this.index = index;
      this.mid = mid;
      this.title = title;
      this.type = type;
    }
  }
  
  export class TrendOverTimeQuery {
    query: string;
  
    constructor(query: string) {
      this.query = query;
    }
  }
  
  export class UserQuery {
    email: string;
    password: string;
  
    constructor(email: string,password: string) {
      this.email = email;
      this.password = password;
    }
  }
  
  
  
    export interface Profile {
        latestSelectedTicker: string;
        latestSelectedInterval: string;
        lastPageVisited: string;
    }
  
    export interface User {
        active: boolean;
        roles?: any;
        profile: Profile;
        id: string;
        username: string;
        normalizedUserName: string;
        email: string;
        normalizedEmail: string;
        emailConfirmed: boolean;
        passwordHash?: any;
        securityStamp: string;
        concurrencyStamp: Date;
        phoneNumber: string;
        phoneNumberConfirmed: boolean;
        twoFactorEnabled: boolean;
        lockoutEnd: Date;
        lockoutEnabled: boolean;
        accessFailedCount: number;
        token: string;
    }
  
  export interface Req_Response {
    message: string
    result: User
  }

export function isNumber(value?: string | number): boolean
{
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
}

export const logoutUser = () => ({
    type: 'USER_LOGOUT'
  });


export class UserProfile {
    email: string;
    token: string;
    username: string;
    constructor(
        email: string,
        token: string,
        username: string,
    ) {
      this.email = email;
      this.token = token;
      this.username = username;
    }
  }


  export interface OrderFillTransaction {
    orderID: number;
    clientOrderID?: string;
    instrument?: string;
    units: number;
    gainQuoteHomeConversionFactor: number;
    lossQuoteHomeConversionFactor: number;
    price: number;
    //ClientPriceRefId: number;
    //fullPrice?: ClientPrice;
    reason?: string;
    pl: number;
    financing: number;
    commission: number;
    guaranteedExecutionFee: number;
    accountBalance: number;
    //OrderFillTransactionIdForTradeOrdered?: number;
    tradeOpened?: TradeOpen;
    tradesClosed?: TradeReduce[];
    //OrderFillTransactionIdForReducedTrade: number;
    tradeReduced?: TradeReduce;
    halfSpreadCost: number;
    id: number;
    type?: string;
    time?: string;
    userID: number;
    accountID?: string;
    batchID: number;
    requestID?: string;
  }
  
  // Additional types
  export interface ClientPrice {
    ClientPriceId: number;
    bids?: PriceBucket[];
    asks?: PriceBucket[];
    closeOutBid: number;
    closeOutAsk: number;
    timestamp?: string;
  }
  
  export interface PriceBucket {
    generated_Id: number;
    Id?: number;
    price: number;
    liquidity: number;
    ClientPriceIdAsk?: number; // Foreign key for buying
    ClientPriceIdBid?: number; // Foreign key for selling
    ClientPriceAsk?: ClientPrice; // Navigation property for buying
    ClientPriceBid?: ClientPrice; // Navigation property for selling
  }
  
  export interface ClientExtensions {
    generated_Id: number;
    id?: string;
    tag?: string;
    comment?: string;
    TradeOpenedfId?: number;
    TradeOpenRef?: TradeOpen;
  }
  export interface TradeOpen {
    ClientExtensionsRefId: number;
    //clientExtensions: ClientExtensions;
    initialMarginRequired: number;
  
    TradeOpenId: number;
    units: number;
    price: number;
    guaranteedExecutionFee?: number;
    halfSpreadCost?: number;
  
    //OrderFillTransactionIdForTradeOrdered?: number;
    //OrderOpenedTransaction?: OrderFillTransaction;
  }
  

  export interface TradeReduce {
    tradeReduceId: number;
    clientTradeID?: string;
    realizedPL: number;
    financing: number;
  
    tradeID: number;
    units: number;
    price: number;
    guaranteedExecutionFee?: number;
    halfSpreadCost?: number;
  
    //OrderFillTransactionIdForClosedTrades?: number;
    //OrderFillTransactionIdForReducedTrade?: number;
  
    //OrderFillClosedTransaction?: OrderFillTransaction;
    //OrderFillReducedTransaction?: OrderFillTransaction;
  }

  export const formatNumber = (number:Number) => {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  export const formatTime = (timeString:string) => {
    const date = new Date(timeString);
    
    // Extracting the components
    const year = date.getFullYear().toString()//.slice(-2); // Last two digits of the year
    const day = date.getDate().toString().padStart(2, '0'); // Day with leading zero
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month with leading zero
    const hours = date.getHours().toString().padStart(2, '0'); // Hours with leading zero
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes with leading zero
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  export function isValidYouTubeUrl(url: string): boolean {
    const youtubeUrlRegex = /^https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?(?:.*&)?v=|embed\/|v\/|)([\w\-]{11})/;
    return youtubeUrlRegex.test(url);
  }

  export enum youtubeConvertAction2 {
    Download = 1,
    Transcript,
  }

  export enum youtubeConvertAction {
    Download = "Download",
    Transcript = "Transcript"
  }

  export  function getEnumKeyByValue(enumObj: any, value: number | string): string {
    return Object.keys(enumObj).find(key => enumObj[key] === value)!;
  }

export function removeExtension(fileName: string): string {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return fileName; // No extension found
    }
    return fileName.substring(0, lastDotIndex);
}

export function generateRandomName () {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export class MessageObject2 {
  public id:string;
  constructor(public sendername: string,public destinatorname: string, public message: string) {
    this.id= generateRandomName()+generateRandomName();
    this.sendername=sendername;
    this.destinatorname=destinatorname;
    this.message= message;
}
}


export interface MessageObject {
  id: string;
  sendername: string;
  realizedPL: string;
  destinatorname: string;
  message: string;

}

interface Color {
  red: number;
  green: number;
  blue: number;
}
const mix: Color = {
  red: 125,  // Replace with your mix values
  green: 125,
  blue: 125
};

export function generateRandomColor(): Color {
  const random = () => Math.floor(Math.random() * 256);

  let red = random();
  let green = random();
  let blue = random();

  red = (red + mix.red) / 2;
  green = (green + mix.green) / 2;
  blue = (blue + mix.blue) / 2;

  return { red, green, blue };
}

export function colorToCssString(color: Color): string {
  return `rgb(${color.red}, ${color.green}, ${color.blue})`;
}

export function getFileExtension(filename: string): string | null {
  const parts = filename.split('.');
  if (parts.length > 1) {
      return parts.pop() || null;
  }
  return null;
}

export function dateToString(date:Date|null) {
    var ddate =date?date:new Date()
    const year = ddate?.getFullYear();
    const month = String(ddate.getMonth() + 1).padStart(2, '0');
    const day = String(ddate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const delay = (ms:any) => new Promise(res => setTimeout(res, ms));