import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface MainModalProps {
    isOpen: boolean;
    toggle: () => void;
}

const MainModal: React.FC<MainModalProps> = ({ isOpen, toggle }) => {
    return (

        <Modal isOpen={isOpen} toggle={toggle} fullscreen>
            <ModalHeader toggle={toggle}>Modal Title</ModalHeader>
            <ModalBody>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default MainModal;

