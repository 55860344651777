// src/features/mentalCalc/mentalCalcSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store'; // Import the AppThunk type if defined
import {getServerUrl, isNumber, UserProfile} from '../util'
import axios,{AxiosError} from 'axios';
import { AuthState, logOutAction } from '../login/login';


export interface MentalCalcState {
    problem: string;
    answer: string;
    score: number;
    timerlength_str:string;
    timerlength_int:number;
    isPlaying:boolean;
    answer_str: string;
    answer_int: number;
    allTimeScore: number;
    countdownkey: number;
}

const initialState: MentalCalcState = {
    problem: '',
    answer: '',
    score: 0,
    timerlength_str:'',
    timerlength_int:0,
    isPlaying:false,
    answer_str: '',
    answer_int: 0,
    allTimeScore: 0,
    countdownkey:0,
};

const mentalCalcSlice = createSlice({
    name: 'mentalCalc',
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<string>) => {
            state.answer_str = action.payload;
            state.answer = action.payload;
        },
        updateProblem: (state, action: PayloadAction<string>) => {
            state.problem = action.payload;
            state.answer = '';
            state.answer_str = ''
        },
        incrementScore: (state) => {
            state.score += 1;
            state.answer_str = '';
        },
        settimerlengthSuccess: (state, action:PayloadAction<string>) => {
            if(action.payload==''){
                state.timerlength_int= Number(0)
                state.timerlength_str= ''
            }
            if(isNumber(action.payload)){
                state.timerlength_int= Math.min(Number(action.payload),300)
                if(state.timerlength_int==300){
                    state.timerlength_str= '300'
                }else{
                    state.timerlength_str= action.payload
                }
                
            }
        },
        settimerlengthError: (state,action:PayloadAction<string>) => {
            state.timerlength_str= state.timerlength_str
        },
        startTimerSuccess: (state) => {
            state.isPlaying= true
            state.countdownkey=(state.countdownkey+1) %100
            state.score = 0
            state.answer = '';
            state.answer_str = ''
        },
        handleTimerCompleteSuccess: (state) => {
            state.isPlaying= false
            state.countdownkey=(state.countdownkey+1) %100
        },
        initiateNewProblem: (state)=>{
            const operands = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
            const operators = ['+', '-', '*', '/'];
            const randomOperator = operators[Math.floor(Math.random() * operators.length)];
            const newProblem = `${operands[0]} ${randomOperator} ${operands[1]}`;
            state.problem = newProblem
            //state.answer_str = ''
            state.isPlaying = false
            state.score = 0
        },
        scoreAlltimeSucessAction: (state, action:PayloadAction<string>) => {
            if(action.payload==''){
                state.allTimeScore= Number(0)
            }
            if(isNumber(action.payload)){
                state.allTimeScore= Number(action.payload)
            }
        },
        
    }
});

export const { setAnswer, updateProblem, incrementScore,
    settimerlengthSuccess, settimerlengthError,startTimerSuccess
,handleTimerCompleteSuccess,initiateNewProblem, scoreAlltimeSucessAction } = mentalCalcSlice.actions;

// Thunk action for handling a guess
export const handleGuess = (): AppThunk => (dispatch, getState) => {
    const { problem, answer } = getState().mentalCalc;
    const correctAnswer = eval(problem);
    console.log('Number(answer)',Number(answer),'correctAnswer',correctAnswer)
    if (Number(answer) === correctAnswer) {
        dispatch(incrementScore());
    }
    dispatch(generateNewProblem());
};

// Thunk action for generating a new problem
// export const generateNewProblem = (): AppThunk => (dispatch) => {
//     const operands = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
//     const operators = ['+', '-', '*', '/'];
//     const randomOperator = operators[Math.floor(Math.random() * operators.length)];
//     const newProblem = `${operands[0]} ${randomOperator} ${operands[1]}`;
//     dispatch(updateProblem(newProblem));
// };
export const generateNewProblem = (): AppThunk => (dispatch) => {
    let operands = [0, 0];
    const operators = ['+', '-', '*', '/'];
    const randomOperator = operators[Math.floor(Math.random() * operators.length)];

    if (randomOperator === '*' || randomOperator === '/') {
        // For multiplication, limit operands to less than 16
        operands = [Math.floor(Math.random() * 16), Math.floor(Math.random() * 16)];
    } else {
        // For other operations, limit operands to less than 100
        operands = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
    }

    if (randomOperator === '/') {
        // Ensure division results in a whole number
        operands[1] = operands[1] === 0 ? 1 : operands[1];  // Prevent division by zero
        operands[0] = operands[0] * operands[1];  // Adjust operand 0 to be a multiple of operand 1
    }

    // Generate the problem string
    let newProblem = `${operands[0]} ${randomOperator} ${operands[1]}`;

    // Ensure the result is a positive integer
    const result = eval(newProblem);
    if (result < 0 || !Number.isInteger(result)) {
        // If result is negative or not an integer, recursively generate a new problem
        return dispatch(generateNewProblem());
    }

    // Dispatch the new problem to the store
    dispatch(updateProblem(newProblem));
};
export const settimerlength = (value: string):  AppThunk => (dispatch) => {
    try {
      console.log(' settimerlength called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(settimerlengthSuccess(value));
    } catch (error) {
      dispatch(settimerlengthError('settimerlength failed '));
    }
  };

  export const startTimer = ():  AppThunk => (dispatch) => {
    try {
      console.log(' startTimer called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(initiateNewProblem())
      dispatch(startTimerSuccess());
    } catch (error) {
        console.log('startTimer threw an error', error)
      //dispatch(settimerlengthError('settimerlength failed '));
    }
  };

  export function handleTimerComplete(elapsedtime:number) {
    // console.warn("==============> SubmitOvertime begin");
    return (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; mentalCalc: MentalCalcState, auth: AuthState }
    ) => {
      const state = getState().mentalCalc as MentalCalcState;
      const logstate = getState().auth as AuthState;

      dispatch(handleTimerCompleteSuccess());
      
      console.log('state handleTimerComplete', state)
      var baseurl = getServerUrl();
      var suburl= "mental/save";
      var body = {'score': state.score};
      
      console.log('body: ', body)
      var userstr =localStorage.getItem("user");  
      let user = JSON.parse(userstr??"{}") as UserProfile;
      console.log('user: ', user)

    const data:any =  [{'isgoogleauth':logstate.isgoogleauth}]

    const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + user.token,
    'isgoogleauth': logstate.isgoogleauth,
    }
    console.log('header',headers,'body',body)
    axios.post(baseurl + suburl,body,{
        headers: headers
      })
        .then(response => {
            console.log('Score Saved: ',response.data)
            //localStorage.setItem("user", JSON.stringify(response.data));
            //dispatch( scoreSavedSucessAction(response.data));
            //store.dispatch(push('/your-route'))
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            if(error.response?.status==401){
                console.log('your token has expired, disconnecting now!')
                dispatch( logOutAction());    
             }
            console.log("error.response?.data",error.response?.data)
            if (error.response?.data) {
                // Stringify the error response data
                const errorDataString = JSON.stringify(error.response.data);
                console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
                // Check if the string contains the specific substring
                if (errorDataString.toLowerCase().includes("token is expired")) {
                    console.log("Token is expired error detected.");
                    dispatch(logOutAction());}
                else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                    console.log("Token invalid error detected.");
                    dispatch(logOutAction());
                } else {
                    console.log("Different error detected.",errorDataString);
                    // Handle other errors
                }
            }
        });

        dispatch(getAllTimeScore());
    };
  }

  export function getAllTimeScore() {
    // console.warn("==============> SubmitOvertime begin");
    return (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; mentalCalc: MentalCalcState, auth: AuthState }
    ) => {
      const state = getState().mentalCalc as MentalCalcState;
      const logstate = getState().auth as AuthState;

      dispatch(handleTimerCompleteSuccess());

      var baseurl = getServerUrl();
      var suburl= "mental/get";

      var userstr =localStorage.getItem("user"); 
      console.log('userstr:',userstr)
      let user = (userstr!=null)?JSON.parse(userstr??'') as UserProfile: null;
      console.log('user: ', user)

    const data:any =  [{'isgoogleauth':logstate.isgoogleauth}]
    const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + user?.token,
    'isgoogleauth': logstate.isgoogleauth,
    }

    console.log('header',headers,'body')
    axios.get(baseurl + suburl,{
        headers: headers
      })
        .then(response => {
            console.log('All time score: ',response.data)
            //localStorage.setItem("user", JSON.stringify(response.data));
            dispatch( scoreAlltimeSucessAction(response.data));
            //store.dispatch(push('/your-route'))
            return response.data;
        })
        .catch((error:AxiosError) => {
            console.error('Error:', error);
            if(error.response?.status==401){
               console.log('your token has expired, disconnecting now!')
               dispatch( logOutAction());    
            }
            console.log("error.response?.data",error.response?.data)
            if (error.response?.data) {
                // Stringify the error response data
                const errorDataString = JSON.stringify(error.response.data);
                console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
                // Check if the string contains the specific substring
                if (errorDataString.toLowerCase().includes("token is expired")) {
                    console.log("Token is expired error detected.");
                    dispatch(logOutAction());}
                else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                    console.log("Token invalid error detected.");
                    dispatch(logOutAction());
                } else {
                    console.log("Different error detected.",errorDataString);
                    // Handle other errors
                }
            }
            //dispatch( loginFailAction(error));
            //localStorage.setItem("user", '');

        });

    };
  }
  

  export const getNewProblem = ():  AppThunk => (dispatch) => {
    try {
      console.log(' getNewProblem called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(generateNewProblem());
    } catch (error) {
        console.log('handleTimerComplete threw an error', error)
      //dispatch(settimerlengthError('settimerlength failed '));
    }
  };
  export const setUserAnswer = (value:string):  AppThunk => (dispatch) => {
    try {
      console.log(' setUserAnswer called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setAnswer(value));
    } catch (error) {
        console.log('handleTimerComplete threw an error', error)
      //dispatch(settimerlengthError('settimerlength failed '));
    }
  };
  
export default mentalCalcSlice.reducer;
