// signalrService.ts
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import { getServerUrl } from "../redux/modules/util";

type ConnectionDictionary = Record<string, HubConnection | null>;

class SignalRService {
  private connections: ConnectionDictionary = {};

  public async createConnection(roomNumber: string): Promise<HubConnection> {
    const baseurl = getServerUrl();

    if (!this.connections[roomNumber]) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${baseurl}chat`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      await connection.start();
      console.log("SignalR connection established. ============++++++++++++++>>>>>>>>>>>>", connection);
      await connection.invoke("JoinSpecificChatRoom", { "UserName": "-", "ChatRoom": roomNumber });

      if (connection.connectionId) {
        await connection.invoke("JoinSpecificChatRoom", { "UserName": "-", "ChatRoom": roomNumber });
      }

      this.connections[roomNumber] = connection;
    }

    return this.connections[roomNumber]!;
  }

  public getConnection(roomNumber: string): HubConnection | null {
    return this.connections[roomNumber] || null;
  }

  public async startConnection(roomNumber: string): Promise<HubConnection | null> {
    const connection = await this.createConnection(roomNumber);

    if (connection.state === HubConnectionState.Disconnected) {
      await connection.start();
    }

    const connectionPromise = new Promise<HubConnection | null>((resolve) => {
      let timeoutId: NodeJS.Timeout;

      const checkConnectionState = () => {
        if (connection.state === HubConnectionState.Connected) {
          clearTimeout(timeoutId);
          resolve(connection);
        }
      };

      const intervalId = setInterval(checkConnectionState, 1000);

      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        resolve(null);
      }, 30000);

      checkConnectionState();
    });

    return connectionPromise;
  }

  public displayConnections() {
    console.log("Connections: ", this.connections);
  }
}

export const signalRService = new SignalRService();
