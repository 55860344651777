import React from 'react';
import './stylesroom.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface ChatRoomListProps {
    rooms: string[];
    currentRoom: string;
    onRoomSelect: (room: string) => void;
    onCreateRoom: () => void;
    onAddUser: (room: string) => void;
  }
  
  const ChatRoomList: React.FC<ChatRoomListProps> = ({ rooms, currentRoom, onRoomSelect, onCreateRoom, onAddUser }) => {
    return (
      <div className="chat-room-list">
        <div className="chat-room-list-header">
          <span>Chat Rooms</span>
          <button onClick={onCreateRoom} >

          <FontAwesomeIcon icon={faPlus} size='xl' onClick={onCreateRoom} 
              style={{marginRight:'10'}}/>
            <i style={{margin:'0'}}>Add room</i>

        </button>
        </div>
        <ul>
          {rooms.map((room) => (
            <li key={room} className={room === currentRoom ? 'active' : ''} onClick={() => onRoomSelect(room)}>
              <span onClick={() => onRoomSelect(room)}>{room}</span>
              <button onClick={() => onAddUser(room)}>
              <FontAwesomeIcon icon={faPlus} size='xs' onClick={() => onAddUser(room)} style={{margin:'0'}}/>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default ChatRoomList;