import React, { useState, useRef, useEffect, LegacyRef, useCallback,createRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Header from '../header/header';

import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { AppThunk } from '../../redux/store'; 
import ReactDOM from 'react-dom';
import { ConvertState, handleConvertedChange, handleConvertingChange, 
  handleDownloadCompletedChange, 
  handleFileChange, handleProcessCompletedChange, 
  handleProcessRunningChange, handleStepChange, setIsYouTube, 
  setSelectedOption, setYoutubeUrl,convertDocument, handleSelectInputChange, 
  handleSelectOutputChange, handleCloseModal,handleYoutubeConvertion } from '../../redux/modules/convert/convert';
import { AuthState } from '../../redux/modules/login/login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import ModalError from '../modaltest/modalerror';


const backgroundColor = '#fff';
const textColor = '#495057';
const borderColor = '#ced4da';
const buttonBackground = '#17a2b8';
const buttonTextColor = '#fff';
const buttonBorderColor = '#17a2b8';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundColor};
  color: ${textColor};
  padding-top: 80px; 
  width: 80%
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${backgroundColor};
  color: ${textColor};
  padding: 20px;
  border: 1px solid ${borderColor};
  border-radius: 10px;
  width: 400px;
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? buttonBackground : backgroundColor)};
  color: ${({ active }) => (active ? buttonTextColor : textColor)};
  border: 1px solid ${({ active }) => (active ? buttonBorderColor : borderColor)};
  transition: all 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  width: 85%; /* Set the width to 85% */
`;

const ButtonContainerYoutube = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  /*margin-top: 10px;*/
  width: 85%; /* Set the width to 85% */
`;

const Button = styled.button`
  background-color: ${buttonBackground};
  color: ${buttonTextColor};
  border: 1px solid ${buttonBorderColor};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-items: top;
  &:hover {
    background-color: darken(${buttonBackground}, 10%);
  }
`;

const ConvertAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const Converting = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: ${buttonBackground};
  color: ${buttonTextColor};
  border-radius: 50%;
  animation: ${ConvertAnimation} 1s infinite;
  margin-top: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${backgroundColor};
  color: ${textColor};
  padding: 20px;
  border: 1px solid ${borderColor};
  border-radius: 10px;
  width: 75%;
  gap: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`;

const Spinner = styled.div`
  border: 4px solid ${backgroundColor};
  border-top: 4px solid ${buttonTextColor};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

interface IProps {
    c_state: ConvertState;
    a_state:AuthState;
}


interface IActions {
    setIsYouTube: typeof setIsYouTube;
    setYoutubeUrl: typeof setYoutubeUrl;
    setSelectedOption: typeof setSelectedOption;
    handleStepChange: typeof handleStepChange;
    handleFileChange: typeof handleFileChange;
    handleConvertingChange: typeof handleConvertingChange;
    handleConvertedChange: typeof handleConvertedChange;
    handleProcessRunningChange: typeof handleProcessRunningChange;
    handleProcessCompletedChange: typeof handleProcessCompletedChange;
    handleDownloadCompletedChange: typeof handleDownloadCompletedChange;
    convertDocument: typeof convertDocument;
    handleSelectOutputChange: typeof handleSelectOutputChange;
    handleSelectInputChange: typeof handleSelectInputChange;
    handleCloseModal: typeof handleCloseModal;
    handleYoutubeConvertion: typeof handleYoutubeConvertion;

  }

type Props = IActions&IProps;

const ConvertComp: React.FC<Props> = (props) => {
  // const [step, setStep] = useState(1);
  // const [file, setFile] = useState<File | null>(null);
  // const [converting, setConverting] = useState(false);
  // const [converted, setConverted] = useState(false);
  // const [processRunning, setProcessRunning] = useState(false);
  // const [processCompleted, setProcessCompleted] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      props.handleFileChange(event.target.files[0]);
      
    }
  };

  const handleConvert = () => {
    props.handleConvertingChange(true);

  };

  const handleProcess = () => {
    props.handleConvertingChange(true);
  };

  const CheckboxContainer = styled.div`
  display: flex;
  align-items: left;
  margin-bottom: 20px;
  width: 75%; /* Align with Container width */
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const selectRef = createRef();

const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.setSelectedOption(event.target.value);
  };

  return (
    <>
    <Header title="File Conversion Tool" />
    <Page style={{marginTop:'50px', width:'100%'}}>
      
       <CheckboxContainer>
        <CheckboxLabel>
          <CheckboxInput
            type="checkbox"
            checked={props.c_state.isYoutube}
            onChange={() => props.setIsYouTube()}
          />
          Is YouTube
        </CheckboxLabel>
      </CheckboxContainer>
      {!props.c_state.isYoutube ? (
        <Container style={{ width:'75%'}}>
        <InputContainer style={{ display:'flex', whiteSpace:'nowrap' , width:'100%', border:'0'}}>
          <i>Input Format</i>
          <Select value={props.c_state.inputSelectedOption} onChange={(e)=>props.handleSelectInputChange(e.target.value)} style={{marginBottom:'0px', height:'100%'}}>
            { props.c_state.input_options?.map((option, index) => (
                <option key={index} value={option}>
                {option}
                </option>
            ))}
          </Select>
          <div style={{gap:'20px'}}></div>
          <i>Output Format</i>
          <Select value={props.c_state.outputSelectedOption} onChange={(e)=>props.handleSelectOutputChange(e.target.value)} 
          style={{marginBottom:'0px', height:'100%'}} disabled={props.c_state.inputSelectedOption==''}>
            { props.c_state.output_options?.map((option, index) => (
                <option key={index} value={option}>
                {option}
                </option>
            ))}
          </Select>

        </InputContainer>

          <Steps>
            <Step active={props.c_state.step === 1}>1</Step>
            <Step active={props.c_state.step === 2}>2</Step>
            <Step active={props.c_state.step === 3}>3</Step>
          </Steps>
          {props.c_state.step === 1 && (
            <Button onClick={() => document.getElementById('fileInput')?.click()} disabled={props.c_state.outputSelectedOption==''}>Pick a File</Button>
          )}
          <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
          {props.c_state.step === 2 && (
            <ButtonContainer>
              {/* <Button onClick={() => props.handleStepChange(1)}>Go Back</Button> */}
              <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:'bottom'}}>
              <FontAwesomeIcon icon={faArrowLeft} size='xl' onClick={() => props.handleStepChange(1)} 
              style={{}}/>
              </div>
              
              {
                  (props.c_state.step === 2 && props.c_state.processRunning )?
                      (<Converting>Converting...</Converting>)
                      :
                      <Button onClick={handleConvert} >Convert</Button>
              }

            </ButtonContainer>
          )}
          {props.c_state.step === 3 && !props.c_state.processRunning && !props.c_state.downloaded &&(
            <ButtonContainer>
                <Button onClick={()=>props.handleDownloadCompletedChange(true)}
                        style={{backgroundColor:'black'}}>Download</Button>
            </ButtonContainer>
          )}
        </Container>
      ) : (
        <InputContainer style={{ width: '75%' }}>
          <Input 
            type="url"
            id="youtubeconverturl" 
            value={props.c_state.youtubeUrl ?? ''}
            onChange={(e) => props.setYoutubeUrl(e.target.value)}
            placeholder="Enter Youtube URL" 
            style={{marginBottom:'0px'}}
            />
          <Select value={props.c_state.youtubeSelectedOption} onChange={handleSelectChange} style={{marginBottom:'0px', height:'100%'}}>
           {/* <option value="download">Download</option>
            <option value="transcript">Get Transcript</option>  */}
            { props.c_state.youtubeSelectOptions?.map((option, index) => (
                <option key={index} value={option}>
                {option}
                </option>
            ))}
          </Select>
          {!props.c_state.processCompleted?<Button onClick={props.handleYoutubeConvertion} disabled={props.c_state.processRunning} style={{marginBottom:'0px'}}>
            {(props.c_state.processRunning) ?  <Spinner />: 'Submit'}
          </Button>
          :
            <Button onClick={()=>props.handleDownloadCompletedChange(true)} style={{backgroundColor:'black'}}>Download</Button>
        }
        </InputContainer>
      )}
      {props.c_state.displayerror && <ModalError message={props.c_state.errormessage} show={props.c_state.displayerror} onClose={()=>props.handleCloseModal("")} />}
    </Page>
    </>
  );
};



const mapStateToProps = (state: RootState): any => ({
    c_state: state.convert,
    a_state: state.auth,
});

const mapDispatchToProps: IActions = {
    setIsYouTube,
    setYoutubeUrl,
    setSelectedOption,
    handleStepChange,
    handleFileChange,
    handleConvertingChange,
    handleConvertedChange,
    handleProcessRunningChange,
    handleProcessCompletedChange,
    handleDownloadCompletedChange,
    convertDocument,
    handleSelectOutputChange,
    handleSelectInputChange,
    handleCloseModal,
    handleYoutubeConvertion,

};

export default connect(mapStateToProps, mapDispatchToProps)(ConvertComp);

