// src/features/oanda/oandaSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, store } from '../../store'; // Import the AppThunk type if defined
import {getServerUrl, isNumber, OrderFillTransaction, TradeOpen, UserProfile} from '../util'
import axios,{AxiosError, AxiosResponse} from 'axios';
import { AuthState, logOutAction } from '../login/login';
import * as _ from "lodash";


export interface OandaState {
    problem: string;
    answer: string;
    score: number;
    timerlength_str:string;
    timerlength_int:number;
    isPlaying:boolean;
    answer_str: string;
    answer_int: number;
    allTimeScore: number;
    countdownkey: number;

    rows:any;
    openRow:number;
    ordersfilled: OrderFillTransaction[];
    tradeOpenedRow: TradeOpen|null;
    tradeOpenedRow2: TradeOpen|undefined;
}

const initialState: OandaState = {
    problem: '',
    answer: '',
    score: 0,
    timerlength_str:'',
    timerlength_int:0,
    isPlaying:false,
    answer_str: '',
    answer_int: 0,
    allTimeScore: 0,
    countdownkey:0,

    openRow:-1,
    rows: [
      {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
        username: '@johndoe',
        details: ['Additional details for John Doe', 'More information...'],
      },
      {
        id: 2,
        firstName: 'Jane',
        lastName: 'Doe',
        username: '@janedoe',
        details: ['Additional details for Jane Doe', 'More information...'],
      },
      {
        id: 3,
        firstName: 'Bob',
        lastName: 'Smith',
        username: '@bobsmith',
        details: ['Additional details for Bob Smith', 'More information...'],
      },
    ],

    ordersfilled: [{ id: 1 } as OrderFillTransaction],
    tradeOpenedRow: null, //{} as TradeOpen,
    tradeOpenedRow2: undefined,
};

const oandaSlice = createSlice({
    name: 'oanda',
    initialState,
    reducers: {
      getOrdersFilledSucessAction: (state, action:PayloadAction<OrderFillTransaction[]>) => {
            if(action.payload!==null){
                state.ordersfilled= action.payload
            }
        },
        handleTimerCompleteSuccess: (state) => {

      },
      toggleRowSucessAction: (state,action:PayloadAction<number>) => {
        if(state.openRow== action.payload){
          state.openRow= -1
          state.tradeOpenedRow = ({} as TradeOpen)
        }else{
          state.openRow= action.payload
          state.tradeOpenedRow = state.ordersfilled[action.payload].tradeOpened??({} as TradeOpen)
        }
        
      },
      sortTable: (state,action:PayloadAction<string>) => {
        if(action.payload=='id'){
          const sortedtable = _.sortBy(state.ordersfilled, o => o.id)
          state.ordersfilled = sortedtable
        }else if(action.payload=='id'){
          const sortedtable = _.sortBy(state.ordersfilled, o => o.id)
          state.ordersfilled = sortedtable
        }
        
      },
    }
});

export const {handleTimerCompleteSuccess,  getOrdersFilledSucessAction, toggleRowSucessAction } = oandaSlice.actions;


export function getOrdersFilled() { //works bur ia not async
  // console.warn("==============> SubmitOvertime begin");
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; oanda: OandaState, auth: AuthState }
  ) => {
    const state = getState().oanda as OandaState;
    const logstate = getState().auth as AuthState;

    dispatch(handleTimerCompleteSuccess());


  try {
    var baseurl = getServerUrl();
    var suburl= "market/getfilledorder";

    var userstr =localStorage.getItem("user");  
    let user = (userstr!='')?JSON.parse(userstr??'') as UserProfile: null;
    console.log('user: ', user)


    const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + user?.token,
    'isgoogleauth': logstate.isgoogleauth,
    }

    console.log('header',headers,'body')

    const response: AxiosResponse<OrderFillTransaction[]>  = await axios.get<OrderFillTransaction[]>(baseurl + suburl, { headers: headers });
    console.log('getOrdersFilled: ', response);
    //console.log('getOrdersFilled value: ', response.data.values);

/*
    let resp:any = []
    await axios.get<OrderFillTransaction[]>(baseurl + suburl, { headers: headers })
                                                                        .then((response:any)=>{
                                                                          resp = response.data as OrderFillTransaction[];
                                                                        });
*/

    dispatch(getOrdersFilledSucessAction(response.data));
    
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error('Error:', error);
      
      if (error.response?.status === 401) {
        console.log('Your token has expired, disconnecting now!');
        dispatch(logOutAction());
      }
        console.log("error.response?.data",error.response?.data)
        if (error.response?.data) {
            // Stringify the error response data
            const errorDataString = JSON.stringify(error.response.data);
            console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
            // Check if the string contains the specific substring
            if (errorDataString.toLowerCase().includes("token is expired")) {
                console.log("Token is expired error detected.");
                dispatch(logOutAction());}
            else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                console.log("Token invalid error detected.");
                dispatch(logOutAction());
            } else {
                console.log("Different error detected.",errorDataString);
                // Handle other errors
            }
        }
    }
  }

  };
}




export async function fetchTodos() {

  const logstate = store.getState().auth as AuthState;

  var baseurl = getServerUrl();
  var suburl= "market/getfilledorder";

  var userstr =localStorage.getItem("user");  
  let user = (userstr!='')?JSON.parse(userstr??'') as UserProfile: null;
  console.log('user: ', user)
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + user?.token,
    'isgoogleauth': logstate.isgoogleauth,
    }

    console.log('header',headers,'body')

    const response = await axios.get(baseurl + suburl, { headers: headers });
    console.log('fetchTodos',response)
  //dispatch({ type: 'todos/todosLoaded', payload: response.todos })
}




export function toggleRow(row:number) { 
  // console.warn("==============> SubmitOvertime begin");
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; oanda: OandaState, auth: AuthState }
  ) => {
    const state = getState().oanda as OandaState;
    const logstate = getState().auth as AuthState;

    dispatch(handleTimerCompleteSuccess());


  try {
    var baseurl = getServerUrl();
    var suburl= "market/getfilledorder";

    var userstr =localStorage.getItem("user");  
    let user = (userstr!='')?JSON.parse(userstr??'') as UserProfile: null;
    console.log('toggleRow state: ', state.openRow)


    dispatch(toggleRowSucessAction(row));

  } catch (error) {
      console.error('Error:', error);
      
  }

  };
}


export function toggleRowHighlight(row:any) { 
  // console.warn("==============> SubmitOvertime begin");
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; oanda: OandaState, auth: AuthState }
  ) => {
    const state = getState().oanda as OandaState;
    const logstate = getState().auth as AuthState;

    //dispatch(handleTimerCompleteSuccess());


  try {


    console.log('toggleRowHighlight state: ===============>>>', row)


    //dispatch(toggleRowSucessAction(row));

  } catch (error) {
      console.error('Error:', error);
      
  }

  };
}

  
export default oandaSlice.reducer;
