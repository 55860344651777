import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import React, { useState, useRef, useEffect, LegacyRef, useCallback } from 'react';
import { Container, Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import {OandaState, fetchTodos, getOrdersFilled, toggleRow, toggleRowHighlight} from '../../redux/modules/oanda/oanda'
import { AppThunk } from '../../redux/store'; 
import ReactDOM from 'react-dom';
import { Collapse, Table } from 'reactstrap';
import { OrderFillTransaction, TradeReduce, formatNumber, formatTime } from '../../redux/modules/util';

import { render } from "react-dom";
import _ from 'lodash'
//import ReactTable from "react-table";
//import "react-table/react-table.css";

import ReactTable from "react-table-6";  
import "react-table-6/react-table.css" 
import Header from '../header/header';


interface IProps {
    rstate: OandaState;
}


interface IActions {

    getOrdersFilled: typeof getOrdersFilled; 
    fetchTodos: typeof fetchTodos;
    toggleRow: typeof toggleRow;
    toggleRowHighlight: typeof toggleRowHighlight;

  }

type Props = IActions&IProps;

const OandaOrderFillTable: React.FC<Props> = (props) => {
    
    const dispatch = useDispatch();
    useEffect(() => {
        props.getOrdersFilled(); //uncomment is needed, works but not async
        const interval = setInterval(() => {
            props.getOrdersFilled();
        }, 600000);
      }, [dispatch]);



    const [rowId, setRowId] = useState(-1);

    const toggleRow = (row:number) => {
        setRowId(row);
      };

    const onRowClick = (state:any, rowInfo:any, column:any, instance:any) => {
    return {
        onClick: (e:any) => {
            console.log('A Td Element was clicked!')
            console.log('it produced this event:', e)
            console.log('It was in this column:', column)
            console.log('It was in this row:', rowInfo)
            console.log('It was in this table instance:', instance)

            props.toggleRowHighlight(rowInfo);
        }
    }
    }
   //console.log('props.rstate.ordersfilled',props.rstate.ordersfilled)
  return (
    <>
    <Header title="Filled Order" />
    <Container fluid style={{ padding: '1px', maxWidth: '90%', margin: '0 auto', marginBottom: '20px'}}>
      <b style={{padding:'20px'}}><u>Oanda orders:</u></b>
      <Row>
        <Col md={{ size: 12 }}>
          <div style={{ overflowX: 'auto', paddingLeft: '2px', paddingTop: '20px', paddingBottom: '20px' , paddingRight: '2px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}>
            <ReactTable
              data={props.rstate.ordersfilled}
              columns={[
                { Header: 'ID', accessor: 'id' , style: { textAlign: 'right'}},
                { Header: 'Order ID', accessor: 'orderID' , style: { textAlign: 'right'}},
                { Header: 'Time', accessor: 'time', Cell: ({ value }) => formatTime(value ?? '') },
                { Header: 'Type', accessor: 'type', Cell: ({ value }) => (value ? value.toLowerCase() : '') , style: { textAlign: 'right'}},
                { Header: 'Reason', accessor: 'reason', Cell: ({ value }) => (value ? value.toLowerCase() : '') , style: { textAlign: 'right'}},
                { Header: 'Instrument', accessor: 'instrument', Cell: ({ value }) => (value ? value.toLowerCase() : '') , style: { textAlign: 'right'}},
                { Header: 'Units', accessor: 'units', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Price', accessor: 'price', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'PL', accessor: 'pl', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Commission', accessor: 'commission', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Financing', accessor: 'financing', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Gain Quote', accessor: 'gainQuoteHomeConversionFactor', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Guaranteed Execution Fee', accessor: 'guaranteedExecutionFee', Cell: ({ value }) => formatNumber(value ?? 0) , style: { textAlign: 'right'}},
                { Header: 'Half Spread Cost', accessor: 'halfSpreadCost', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Loss Quote', accessor: 'lossQuoteHomeConversionFactor', Cell: ({ value }) => formatNumber(value??0) , style: { textAlign: 'right'}},
                { Header: 'Account Balance', accessor: 'accountBalance', Cell: ({ value }) => formatNumber(value??0) },
                { Header: 'User ID', accessor: 'userID', style: { textAlign: 'right'} }
              ]}
              defaultPageSize={25}
              showPagination={true}
              getTrProps={onRowClick}
              SubComponent={(row) => {
                console.log('row44: ', row.original)
                console.log('props?.rstate?.tradeOpenedRow',props?.rstate?.tradeOpenedRow)
                //props?.rstate?.tradeOpenedRow
                let trade = {...row.original?.tradeOpened}
                let closedobj = {...row.original?.tradesClosed}
                let reduced = {...row.original?.tradeReduced} as TradeReduce

                let closed = _.values<TradeReduce>(closedobj);

                console.log('trade: ',trade)
                console.log('closed: ',closed)
                console.log('reduced: ',reduced)

                return (
                    <div style={{ padding: "20px" }}>
                    {trade &&(trade.tradeOpenId) && (
                      <div>
                        <b><u>Trade Opened</u></b>
                        <ReactTable
                          data={[trade]}
                          columns={[
                            { Header: 'TradeID', accessor: 'tradeOpenId', Cell: ({ value }) => (value??0), style: { textAlign: 'right'}},
                            { Header: 'Units', accessor: 'units', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Price', accessor: 'price', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Guaranteed Execution Fee', accessor: 'guaranteedExecutionFee', Cell: ({ value }) => formatNumber(value ?? 0), style: { textAlign: 'right'} },
                            { Header: 'Half Spread Cost', accessor: 'halfSpreadCost', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                          ]}
                          defaultPageSize={1}
                          showPagination={false}
                        />
                      </div>
                    )}
                    {reduced  &&(reduced?.tradeReduceId)&& (
                      <div>
                        <b><u>Trade reduced</u></b>
                        <ReactTable
                          data={[reduced]}
                          columns={[
                            { Header: 'TradeID', accessor: 'tradeID', Cell: ({ value }) => (value??0), style: { textAlign: 'right'} },
                            { Header: 'Units', accessor: 'units', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Price', accessor: 'price', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Guaranteed Execution Fee', accessor: 'guaranteedExecutionFee', Cell: ({ value }) => formatNumber(value ?? 0), style: { textAlign: 'right'} },
                            { Header: 'Half Spread Cost', accessor: 'halfSpreadCost', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Realized PL', accessor: 'realizedPL', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Financing', accessor: 'financing', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                          ]}
                          defaultPageSize={1}
                          showPagination={false}
                        />
                      </div>
                    )}
                    {closed &&(closed.length>0) && (
                      <div>
                        <b><u>Trade Closed</u></b>
                        <ReactTable
                          data={closed}
                          columns={[
                            { Header: 'TradeID', accessor: 'tradeID', Cell: ({ value }) => (value??0), style: { textAlign: 'right'} },
                            { Header: 'Units', accessor: 'units', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Price', accessor: 'price', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Guaranteed Execution Fee', accessor: 'guaranteedExecutionFee', Cell: ({ value }) => formatNumber(value ?? 0), style: { textAlign: 'right'} },
                            { Header: 'Half Spread Cost', accessor: 'halfSpreadCost', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Realized PL', accessor: 'realizedPL', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                            { Header: 'Financing', accessor: 'financing', Cell: ({ value }) => formatNumber(value??0), style: { textAlign: 'right'} },
                          ]}
                          defaultPageSize={2}
                          showPagination={false}
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
    </>

  );
};


const mapStateToProps = (state: RootState): any => ({
    rstate: state.oanda,
});

const mapDispatchToProps: IActions = {
    getOrdersFilled,
    fetchTodos,
    toggleRow,
    toggleRowHighlight,

};

export default connect(mapStateToProps, mapDispatchToProps)(OandaOrderFillTable);



/*

    <Table bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Username</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr onClick={() => toggleRow(1)}>
          <th scope="row">1</th>
          <td>John</td>
          <td>Doe</td>
          <td>@johndoe</td>
          <td>
            <Button color="primary" onClick={() => toggleRow(1)}>
              {rowId==1 ? 'Hide' : 'Show'} Details
            </Button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: 0, border: 'none' }}>
            <Collapse isOpen={rowId==1} >
              <Table bordered>
                <tbody>
                  <tr>
                    <td >Additional details for John Doe</td>
                  </tr>
                  <tr>
                    <td >More information...</td>
                  </tr>
                </tbody>
              </Table>
            </Collapse>
          </td>
        </tr>

        <tr onClick={() => toggleRow(3)}>
          <th scope="row">2</th>
          <td>Jane</td>
          <td>Doe</td>
          <td>@janedoe</td>
          <td>
            <Button color="primary" onClick={() => toggleRow(3)}>
              {(rowId==3) ? 'Hide' : 'Show'} Details
            </Button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: 0, border: 'none' }}>
            <Collapse isOpen={(rowId==3)}>
              <Table bordered>
                <tbody>
                  <tr>
                    <td >Additional details for Jane Doe</td>
                  </tr>
                  <tr>
                    <td >More information...</td>
                  </tr>
                </tbody>
              </Table>
            </Collapse>
          </td>
        </tr>

        <tr onClick={() => toggleRow(4)}>
          <th scope="row">3</th>
          <td>Bob</td>
          <td>Smith</td>
          <td>@bobsmith</td>
          <td>
            <Button color="primary" onClick={() => toggleRow(4)}>
              {(rowId==4) ? 'Hide' : 'Show'} Details
            </Button>
          </td>
        </tr>
        <tr>
          <td  style={{ padding: 0, border: 'none' }}>
            <Collapse isOpen={(rowId==4)}>
              <Table bordered>
                <tbody>
                  <tr>
                    <td >Additional details for Bob Smith</td>
                  </tr>
                  <tr>
                    <td >More information...</td>
                  </tr>
                </tbody>
              </Table>
            </Collapse>
          </td>
        </tr>
      </tbody>
    </Table>

*/












/*



    <Container fluid style={{ padding: '0 15px' }}>
    <Row>
      <Col md="12" className="text-center mb-4">
        <h2 style={{ color: '#495057', fontSize: '24px', fontWeight: 'bold' }}>Collapsible Row Table</h2>
      </Col>
      <Col md={{ size: 10, offset: 1 }}>
        <div style={{ overflowX: 'auto' }}>
          <Table bordered style={{
            backgroundColor: '#fff',
            borderColor: '#cfe2ff', // Light blue border color
            textAlign: 'center',
            borderRadius: '8px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            fontSize: '10px',
            minWidth: '1200px' // Ensure the table has a minimum width to fit all columns
          }}>
            <thead style={{
              backgroundColor: '#343a40', // Dark header background
              color: '#89cff0', // Light blue text color
              fontWeight: 'bold',
              textTransform: 'lowercase',
              fontSize: '10px',
            }}>
              <tr>
                <th>#</th>
                <th>Acct Balance</th>
                <th>Commission</th>
                <th>Financing</th>
                <th>Gain Quote</th>
                <th>Exec Fee</th>
                <th>Half Spread Cost</th>
                <th>ID</th>
                <th>Instrument</th>
                <th>Loss Quote</th>
                <th>Order ID</th>
                <th>PL</th>
                <th>Price</th>
                <th>Reason</th>
                <th>Time</th>
                <th>Type</th>
                <th>Units</th>
                <th>User ID</th>
              </tr>
            </thead>
            <tbody>
              {props.rstate?.ordersfilled?.map((row) => (
                <React.Fragment key={row.id}>
                  <tr
                    style={
                      props.rstate.openRow === row.id
                        ? { backgroundColor: '#e9ecef', transition: 'background-color 0.3s ease' }
                        : { backgroundColor: '#fff' }
                    }
                    onClick={() => props.toggleRow(row.id)}
                  >
                    <td scope="row" style={{ fontWeight: 'bold' }}>
                      {row.id}
                    </td>
                    <td>{formatNumber(row.accountBalance)}</td>
                    <td>{formatNumber(row.commission)}</td>
                    <td>{formatNumber(row.financing)}</td>
                    <td>{formatNumber(row.gainQuoteHomeConversionFactor)}</td>
                    <td>{formatNumber(row.guaranteedExecutionFee)}</td>
                    <td>{formatNumber(row.halfSpreadCost)}</td>
                    <td>{row.id}</td>
                    <td>{row.instrument?.toLowerCase()}</td>
                    <td>{formatNumber(row.lossQuoteHomeConversionFactor)}</td>
                    <td>{row.orderID}</td>
                    <td>{formatNumber(row.pl)}</td>
                    <td>{formatNumber(row.price)}</td>
                    <td>{row.reason?.toLowerCase()}</td>
                    <td>{formatTime(row.time??'')}</td>
                    <td>{row.type?.toLowerCase()}</td>
                    <td>{formatNumber(row.units)}</td>
                    <td>{row.userID}</td>
                  </tr>
                  <tr
                    style={{
                      display: props.rstate.openRow === row.id ? 'table-row' : 'none',
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <td colSpan={21} style={{ padding: 0 }}>
                      <Table bordered style={{
                        margin: 0,
                        borderRadius: '4px',
                        overflow: 'hidden',
                        fontSize: '10px'
                      }}>
                        <thead style={{
                          backgroundColor: '#f1f1f1',
                          color: '#333',
                          fontWeight: 'bold'
                        }}>
                          <tr onClick={() => props.toggleRow(-1)}>
                            <th>Generated ID</th>
                            <th>Client Trade ID</th>
                            <th>Realized PL</th>
                            <th>Financing</th>
                            <th>Trade ID</th>
                            <th>Units</th>
                            <th>Price</th>
                            <th>Guaranteed Execution Fee</th>
                            <th>Half Spread Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.tradesClosed?.map((rowtradeclosed, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#fff' }} onClick={() => props.toggleRow(-1)}>
                              <td style={{ padding: '8px 16px' }}>{rowtradeclosed.generated_Id}</td>
                              <td style={{ padding: '8px 16px' }}>{rowtradeclosed.clientTradeID}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.realizedPL)}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.financing)}</td>
                              <td style={{ padding: '8px 16px' }}>{rowtradeclosed.tradeID}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.units)}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.price)}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.guaranteedExecutionFee??0)}</td>
                              <td style={{ padding: '8px 16px' }}>{formatNumber(rowtradeclosed.halfSpreadCost??0)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </Container>


*/