// src/features/convert/convertSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, store } from '../../store'; // Import the AppThunk type if defined
import {getEnumKeyByValue, getFileExtension, getServerUrl,
  isNumber, OrderFillTransaction, removeExtension, TradeOpen,
  UserProfile, youtubeConvertAction, delay} from '../util'
import axios,{AxiosError, AxiosResponse} from 'axios';
import { AuthState, logOutAction } from '../login/login';
import { saveAs } from 'file-saver';
const _ = require('lodash');



export interface ConvertState {
    isYoutube: boolean;
    youtubeUrl: string;
    youtubeSelectedOption: string;
    youtubeSelectOptions: string[];
    convertAction:youtubeConvertAction;
    step: number;
    file: File | null;
    converting: boolean;
    converted: boolean;
    processRunning: boolean;
    processCompleted: boolean;
    downloaded: boolean;
    input_options: string [];
    input_options_txt: string [];
    input_options_audio_video: string [];
    output_options: string [];
    ouput_options_audio_video: string [];
    inputSelectedOption: string;
    outputSelectedOption: string;
    errormessage: string;
    displayerror: boolean;
    fileId:string;
    youtubeTitle:string;

}

const initialState: ConvertState = {
    isYoutube: false,
    youtubeUrl: '',
    youtubeSelectedOption: 'Download',
    youtubeSelectOptions:["Download","Transcribe"],
    convertAction:youtubeConvertAction.Download,
    step: 1,
    file: null,
    converting: false,
    converted: false,
    processRunning: false,
    processCompleted: false,
    downloaded:false,
    inputSelectedOption: '',
    outputSelectedOption: '',
    
    input_options_txt : ["Azw4","Chm","Comic","Djvu","Docx","Epub","Fb2","Htlz","html","Lit",
    "Lrf","Mobi","Odt","Pdb","Pdf","Pml","Rb","Rtf","Snb","Tcr","Txt"
  ],
  input_options_audio_video:  ['Mp3','Mp4','Wma'],
  output_options : ["","Docx","Epub","Fb2","Html","Htmlz","Lit","Lrf","Mobi","Oeb",
    "Pdb","Pdf","Pml","Rb","Rtf","Snb","Tcr","Txt","Txtz"
  ],
  input_options: _.sortedUniq(["","Azw4","Chm","Comic","Djvu","Docx","Epub","Fb2","Htlz","html","Lit",
  "Lrf","Mobi",'Mp3','Mp4',"Odt","Pdb","Pdf","Pml","Rb","Rtf","Snb","Tcr","Txt",'Wma'
]),

  ouput_options_audio_video:  ['','Mp3','Mp4','Wma'],
  errormessage: "",
  displayerror: false,
  fileId:"",
  youtubeTitle:"default",

};

const convertSlice = createSlice({
    name: 'convert',
    initialState,
    reducers: {

      setIsYouTubeSucessAction: (state) => {
        state.isYoutube=!state.isYoutube
        state.converted = false;
        state.processRunning = false;
        state.processCompleted = false;
        state.step =1;

        state.file= null;
        state.converting= false;
        state.converted= false;
        state.processCompleted= false;
        if(state.isYoutube){
          state.outputSelectedOption="mp4";
        }
      },
      setYoutubeUrlSuccess: (state, action: PayloadAction<string>) => {
        //if(isValidYouTubeUrl(action.payload))
        state.youtubeUrl = action.payload;
        console.log('state.youtubeUrl: ',state.youtubeUrl)
      },
      setSelectedOptionSuccess: (state, action: PayloadAction<youtubeConvertAction>) => {
        //if(isValidYouTubeUrl(action.payload))
        state.convertAction = action.payload;
        state.youtubeSelectedOption = action.payload;
        console.log('state.youtubeSelectedOption',state.youtubeSelectedOption, 'state.isYoutube: ',state.isYoutube)
        

        if(state.isYoutube){
          if(state.youtubeSelectedOption==="Download"){
            console.log('state.youtubeSelectedOption is: ', state.youtubeSelectedOption+"'")
            state.outputSelectedOption="mp4";
          }else if(state.youtubeSelectedOption==="Transcribe"){
            state.outputSelectedOption="txt";
          }
        }
        console.log('state.outputSelectedOption: ', state.outputSelectedOption)
      },
      setStep: (state, action: PayloadAction<number>) => {
        state.step = action.payload;
        if(state.step==1){
          state.downloaded = false;
          state.converted = false;
          state.processRunning = false;
          state.processCompleted = false;
        }
      },
      setFile: (state, action: PayloadAction<File | null>) => {
        state.file = action.payload;
        state.processCompleted = false;
        state.converting=false;
        state.processRunning=false;
      },
      setConverting: (state, action: PayloadAction<boolean>) => {
        state.converting = action.payload;
        if(state.converting===true){
          state.processRunning = true;
          state.downloaded=false;
          //state.converted=false;
        }
      },
      setConverted: (state, action: PayloadAction<boolean>) => {
        state.converted = action.payload;
        if(state.converted==true){
          state.processRunning = false;
          state.converting = false;
        }
      },
      setProcessRunning: (state, action: PayloadAction<boolean>) => {
        state.processRunning = action.payload;
        state.processCompleted = false;
        console.log('state.processRunning: ',state.processRunning)
      },
      setProcessCompleted: (state, action: PayloadAction<boolean>) => {
        state.processCompleted = action.payload;
        if(state.processCompleted){
          //state.youtubeUrl='';
        }
        
      },
      setDownloadCompleted: (state, action: PayloadAction<boolean>) => {
        state.downloaded = action.payload;
        state.converted = false;
        state.processRunning = false;
        state.step =1;

        state.file= null;
        state.converting= false;
        state.processCompleted= true;
        state.youtubeUrl='';
      },
      setSelectInput: (state, action: PayloadAction<string>) => {
        state.inputSelectedOption = action.payload;
        const txtin=_.cloneDeep(state.input_options);
        if(_.includes(txtin, action.payload)){
          var outtxt=_.remove(txtin, function(n:string) {
            return !(n === state.inputSelectedOption);
          });
 
          outtxt = _.sortBy(outtxt);
          state.output_options=outtxt;
          console.log('state.output_options 1',state.output_options)
          console.log('state.input_options 1',state.input_options)
        }
        else{
          state.output_options=_.sortBy(state.output_options)
        }

      },
      setSelectOutput: (state, action: PayloadAction<string>) => {
        state.outputSelectedOption = action.payload;
      },

      setErrorMessage: (state, action: PayloadAction<string>) => {
        state.errormessage = action.payload;
      },
      setDisplayMessage: (state, action: PayloadAction<boolean>) => {
        state.displayerror = action.payload;
      },
      setFileId: (state, action: PayloadAction<string>) => {
        state.fileId = action.payload;
      },
      setYoutubeTitle: (state, action: PayloadAction<string>) => {
        state.youtubeTitle = action.payload;
      },
    }
});

export const {
    setIsYouTubeSucessAction, setYoutubeUrlSuccess, setSelectedOptionSuccess,
    setStep, setFile, setConverting, setConverted, setProcessRunning, setProcessCompleted,
    setDownloadCompleted, setSelectInput, setSelectOutput, setErrorMessage,
    setDisplayMessage,setFileId,setYoutubeTitle} = convertSlice.actions;












export function toggleRowHighlight(row:any) { 
  // console.warn("==============> SubmitOvertime begin");
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; convert: ConvertState, auth: AuthState }
  ) => {
    const state = getState().convert as ConvertState;
    const logstate = getState().auth as AuthState;

    //dispatch(handleTimerCompleteSuccess());


  try {


    console.log('toggleRowHighlight state: ===============>>>', row)


    //dispatch(toggleRowSucessAction(row));

  } catch (error) {
      console.error('Error:', error);
      
  }

  };
}
export function setIsYouTube() { 
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState, auth: AuthState }
    ) => {
        const state = getState().convert as ConvertState;
        const logstate = getState().auth as AuthState;
        try {
        dispatch(setIsYouTubeSucessAction());
        } catch (error) {
            console.error('Error:', error); 
        }
    };
  }

  export function setYoutubeUrl(url: string) { 
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState, auth: AuthState }
    ) => {
        console.log('setYoutubeUrl: ', url)
        const state = getState().convert as ConvertState;
        const logstate = getState().auth as AuthState;
        try {
        dispatch(setYoutubeUrlSuccess(url));
        } catch (error) {
            console.error('Error:', error); 
        }
    };
  }


  export function setSelectedOption(youtubeaction: string) { 
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState, auth: AuthState }
    ) => {
        const state = getState().convert as ConvertState;
        const logstate = getState().auth as AuthState;
        try {


            dispatch(setSelectedOptionSuccess(youtubeaction as youtubeConvertAction));
        } catch (error) {
            console.error('Error:', error); 
        }
    };
  }
  



  export function handleStepChange(newStep: number) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      console.log('handleStepChange called: ', newStep)
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        dispatch(setStep(newStep));
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
  
  export function handleFileChange(newFile: File | null) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        console.log("you selected file:  ",newFile?.name, newFile?.type?.toLowerCase())
        console.log('state.inputSelectedOption: ',state.inputSelectedOption?.toLowerCase())
        console.log('newFile?.type: ',newFile?.type.toLowerCase())
        console.log('file extension: ', getFileExtension(newFile?.name??""))
        if((getFileExtension(newFile?.name??"")??"").toLowerCase().includes(state.inputSelectedOption?.toLowerCase()??"")){
          dispatch(setFile(newFile));
          dispatch(handleStepChange(2));
        }else{
          const step = Math.max(state.step-1,1)
          dispatch(setStep(step));
          dispatch(setErrorMessage("File chosen does not match input selected. Try again."));
          dispatch(setDisplayMessage(true))
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
 
  export function handleConvertingChange(isConverting: boolean) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {

        dispatch(setProcessCompleted(false));
        dispatch(setProcessRunning(true));
        await delay(30000)
        dispatch(setConverting(true));


        const formData = new FormData();
        //const blob = new Blob([state.file??undefined], { type: state.file?.type });
        
        formData.append('file', state.file as any);
        formData.append('inputformat', state.inputSelectedOption);
        formData.append('outputformat', state.outputSelectedOption);
        

        console.log('formData',formData);
      
        try {

          const headers = {
            'Content-Type': 'multipart/form-data',
            }
          
          var baseurl = getServerUrl();
          var suburl = "conversionhub/convert";
          axios.post(baseurl + suburl, formData,{
            headers: headers
          })
          .then(response => {
              console.log('convert convert response: ',response.data)
              dispatch(setFileId(response.data.fileId))
              dispatch(handleStepChange(3));
              dispatch(setConverted(true));
              dispatch(setProcessCompleted(true));   
              return response.data;
          })
          .catch(error => {
              console.error('Error:', error);
              dispatch(handleStepChange(1));
              dispatch(setConverted(true));
              dispatch(setProcessCompleted(true));

            console.log("error.response?.data",error.response?.data)
            if (error.response?.data) {
              // Stringify the error response data
              const errorDataString = JSON.stringify(error.response.data);
              console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
              // Check if the string contains the specific substring
              if (errorDataString.toLowerCase().includes("token is expired")) {
                console.log("Token is expired error detected.");
                dispatch(logOutAction());}
              else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                console.log("Token invalid error detected.");
                dispatch(logOutAction());
              } else {
                console.log("Different error detected.",errorDataString);
                // Handle other errors
              }
            }
          });

          //dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response.data });
        } catch (error) {
          console.log(error)
          dispatch(setFileId(''))
          dispatch(handleStepChange(1));
          dispatch(setConverted(true));
          dispatch(setProcessCompleted(true));  
          //dispatch({ type: UPLOAD_FILE_FAILURE, payload: error.message });

        }


   
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
  
  
  export function handleConvertedChange(isConverted: boolean) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        dispatch(setConverted(isConverted));
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  

  export function handleProcessRunningChange(isProcessRunning: boolean) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        dispatch(setProcessRunning(isProcessRunning));
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
  
  
  export function handleProcessCompletedChange(isProcessCompleted: boolean) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        dispatch(setProcessCompleted(isProcessCompleted));
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
  
  export function handleDownloadCompletedChange(isDownloadCompleted: boolean) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {

        const fileId= state.fileId;
        console.log('fileId called ===>', state.fileId)
        try {


          var baseurl = getServerUrl();
          var suburl = 'conversionhub/downloadfileId';
          // Step 3: Make a POST request using the processed filename
          let outfilename=''

          if(!state.isYoutube){
            outfilename = removeExtension(state.file?.name??'') +'.'+state.outputSelectedOption;
          }else{
            outfilename=state.youtubeTitle
          }

          fetch(baseurl+suburl,
          { 
              method: "POST",
              headers: { "Content-Type": "application/json"},
              body: JSON.stringify({data:fileId}),
          }).then(response => response.blob()).then(response => {
            console.log('response: ',response)
            saveAs(response, outfilename);
          } ).catch(error => {
            // Handle the error
            console.error('Fetch error:', error.message);
            if (error.response?.data) {
              // Stringify the error response data
              const errorDataString = JSON.stringify(error.response.data);
              console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
              // Check if the string contains the specific substring
              if (errorDataString.toLowerCase().includes("token is expired")) {
                console.log("Token is expired error detected.");
                dispatch(logOutAction());}
              else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                console.log("Token invalid error detected.");
                dispatch(logOutAction());
              } else {
                console.log("Different error detected.",errorDataString);
                // Handle other errors
              }
            }

          })
        } catch (error) {
          console.error('There was an error downloading the file!', error);
          //alert('There was an error downloading the file!');
          dispatch(setDownloadCompleted(isDownloadCompleted));
          dispatch(setProcessCompleted(false))


        }


        dispatch(setDownloadCompleted(isDownloadCompleted));
        dispatch(setProcessCompleted(false))
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }

  
  export function convertDocument() {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        console.log('convertDocument ran')
        dispatch(setProcessCompleted(true));
        console.log('state: ',state)
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }

  export function handleSelectInputChange(input:string) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        console.log('convertDocument ran')
        dispatch(setSelectInput(input));
        console.log('state: ',state)
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }

  export function handleSelectOutputChange(output:string) {
    return async (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
    ) => {
      const state = getState().convert as ConvertState;
      const logstate = getState().auth as AuthState;
  
      try {
        console.log('convertDocument ran')
        dispatch(setSelectOutput(output));
        console.log('state: ',state)
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  
export function handleCloseModal(output:string) {
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
  ) => {
    const state = getState().convert as ConvertState;
    const logstate = getState().auth as AuthState;

    try {
      dispatch(setErrorMessage(""));
      dispatch(setDisplayMessage(false));
    } catch (error) {
      console.error('Error:', error);
    }
  };
}



export function handleYoutubeConvertion() {
  return async (
    dispatch: (arg0: any) => void,
    getState: () => { (): any; new (): any; convert: ConvertState; auth: AuthState }
  ) => {
    const state = getState().convert as ConvertState;
    const logstate = getState().auth as AuthState;

    try {

      dispatch(setProcessCompleted(false));
      dispatch(setProcessRunning(true));
      dispatch(setConverting(true));


      const formData = new FormData();
      //const blob = new Blob([state.file??undefined], { type: state.file?.type });
      formData.append('url', state.youtubeUrl as any);
      formData.append('action', state.youtubeSelectedOption);
    
      try {
        const headers = {
          'Content-Type': 'application/json',
          }
          var baseurl = getServerUrl();
          var suburl = 'api/file/convertyoutube';
        axios.post(baseurl+suburl, {"url":state.youtubeUrl,"action":state.youtubeSelectedOption},{
          headers: headers
        })
        .then(response => {
            console.log('convert convertyoutube response: ',response.data)
            dispatch(setFileId(response.data?.result?.fileId))
            dispatch(setYoutubeTitle(response.data?.result?.title))
            dispatch(handleStepChange(3));
            dispatch(setConverted(true));
            dispatch(setProcessCompleted(true));   
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            dispatch(handleStepChange(1));
            dispatch(setConverted(true));
            dispatch(setDownloadCompleted(true));
            dispatch(setProcessCompleted(false))
        });
        
        //dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response.data });
      } catch (error) {
        console.log(error)
        dispatch(setFileId(''))
        //dispatch({ type: UPLOAD_FILE_FAILURE, payload: error.message });
      }


      
        
   
    } catch (error) {
      console.error('Error:', error);
    }
  };
}

export const Timeout = (time:number ) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};

export default convertSlice.reducer;
