import React from 'react';
import { MessageObject } from '../../redux/modules/util';
import './stylesroom2.css';

// interface Message {
//   id: number;
//   user: string;
//   text: string;
// }

interface Message {
    sendername: string;
    destinatorname: string;
    message: string;
    id: string;
}

interface ChatBubbleProps {
  message: MessageObject;
  currentUser: string;
  userColors: { [key: string]: string };
}

//const ChatBubble: React.FC<ChatBubbleProps> = ({ message, currentUser, userColors }) => {

type Props = ChatBubbleProps;
const ChatBubble: React.FC<Props> = (props) => {    
  
  const isCurrentUser = props.message.sendername === props.currentUser;
  //console.log('ChatBubble called with: ======__________888888_____________===>', isCurrentUser)
  const bubbleClass = isCurrentUser ? 'bubble current-user' : 'bubble';
  const bubbleStyle = {
    backgroundColor: isCurrentUser ?"#007bff":props.userColors[props.message.sendername],//props.userColors[props.message.sendername],
    alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  };

  return (
    // <div className={bubbleClass} style={bubbleStyle}>
    //   <div className="message-content">{props.message.message}</div>
    //   <div className="message-user">{props.message.sendername}</div>
    // </div>
    <div className={'bubble'} style={bubbleStyle}>
      <div className="message-content">{props.message.message}</div>
      <div className="message-user">{props.message.sendername}</div>
    </div>
  );
};

export default ChatBubble;


/*

import React from 'react';
import './styles.css';

interface Message {
  id: number;
  user: string;
  text: string;
}

interface ChatBubbleProps {
  message: Message;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ message }) => {
  const bubbleClass = message.user === 'User1' ? 'bubble user1' : 'bubble user2';

  return (
    <div className={bubbleClass}>
      <div className="message-content">{message.text}</div>
      <div className="message-user">{message.user}</div>
    </div>
  );
};

export default ChatBubble;

*/
