import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardImg, CardTitle, CardText, Col, Container, Row, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import { HomepageState, setUserAnswer, } from '../../redux/modules/homepage/homepage';
//import { useHistory } from 'react-router-dom';
import {Link} from "react-router-dom";
//import './homepage.css';

import src from './landingpage.mp4';
import chartjpeg from './chart.jpeg';
import geojpg from './geometryg136510dee_1280.jpeg';
import chart3d from './chart3d.jpeg';
import pxfuel from './pxfuel.jpeg';
import t3d_plot from './3d_plot.jpeg';
import circuit from './circuit.jpeg';
import Carousel7 from './kobe.jpg';
import Carousel8 from './kobe2.jpg';
import Carousel9 from './kobe3.jpg';
import Carousel10 from './kobe4.png';
import Carousel11 from './kobe5.jpg';
import { RootState } from '../../redux/store';

interface IProps {
    btnState: HomepageState;
  }
  //type MyState = {};
  interface MyState {
    isFlipped_1: boolean;
    isFlipped_2: boolean;
    isFlipped_3: boolean;
    isFlipped_4: boolean;
    isFlipped_5: boolean;
    isFlipped_6: boolean;
    isFlipped: any[];
    activeIndex:number;
    animating: boolean;
  }
  
  interface IActions {
    setUserAnswer: typeof setUserAnswer;

  }

class HomepageInner extends React.Component<
IProps & IActions,
MyState
> {
  constructor(props:any) {
    super(props);
    this.state = {
      activeIndex: 0,
      isFlipped: Array(6).fill(false),
      isFlipped_1: false,
      isFlipped_2: false,
      isFlipped_3: false,
      isFlipped_4: false,
      isFlipped_5: false,
      isFlipped_6: false,
      animating:false,
      
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.handleFlip = this.handleFlip.bind(this);

  }

  //histo :any = useHistory()
  modulestitle=["Charts","AI Chat","Volatily Map", "Mental Calculation","Market Data","Slice of Life"]
  modulestext=[
      "Explore various trading KPIs and reports. Users can visualize key performance indicators and detailed financial reports, aiding in the analysis of market trends and investment decisions.",
      "Interactive artificial intelligence platform that assists users with specific inquiries about data analysis, providing insights and explanations to help inform decisions across various contexts.",
      "Visually tracks and analyzes market volatility, offering users a comprehensive view of market dynamics. It identifies areas of high fluctuation, aiding traders and analysts in assessing potential risks and opportunities.",
      "Designed to strengthen users' arithmetic skills through structured practice sessions. This tool focuses on improving speed and accuracy in calculations, beneficial for educational purposes and everyday problem-solving.",
      "Delivers real-time financial data, presenting users with the latest market trends, stock prices, and economic indicators. This feature is crucial for investors and analysts needing timely information for strategic decision-making.",
      "A unique compilation of life advice, tips, and reminders gathered from various experiences. This page serves as a patchwork of wisdom, offering practical insights and reflections to enhance everyday living and personal growth."
  
  ]
  onExiting = () => {
    //this.state.animating = true;
  }

  onExited = () => {
    //this.state.animating = false;
  }

  next() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.state.isFlipped.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.isFlipped.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex:number) {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  handleFlip(index:number) {
    let flips = this.state.isFlipped.slice();
    flips[index] = !flips[index];
    this.setState({ isFlipped: flips });
  }

  handleClick(index:number) {
    
    switch (index) {
        case 0:
            //this.histo.push('/mentalcalc'); 
            break;
        case 1:
            //this.histo.push('/'); 
            break;
        case 2:
            //this.histo.push('/profile'); 
            break;
        case 3:
            //this.histo.push('/mentalcalc'); 
            break;
        case 4:
            //this.histo.push('/mentalcalc'); 
            break;
        case 5:
            //this.histo.push('/mentalcalc'); 
            break;
        case 6:
            //this.histo.push('/mentalcalc'); 
            break;
        default:
            console.error('Invalid user role');
    }
    
  }
  getLink(index:number) {
    
    switch (index) {
        case 0:
            return '/mentalcalc'
            break;
        case 1:
            return '/'
            break;
        case 2:
            return '/profile'
            break;
        case 3:
            return '/mentalcalc'
            break;
        case 4:
            return '/mentalcalc'
            break;
        case 5:
            return '/mentalcalc'
            break;
        case 6:
            return '/mentalcalc'
            break;
        default:
            console.error('Invalid user role');
    }
    
  }
  render() {
    const { activeIndex } = this.state;
    const items = [
      { src: Carousel7, altText: 'Slide 1', caption: 'Focus' },
      { src: Carousel8, altText: 'Slide 2', caption: '' },
      //{ src: Carousel12, altText: 'Slide 6', caption: '' },
      { src: Carousel9, altText: 'Slide 3', caption: 'Muse' },
      { src: Carousel10, altText: 'Slide 4', caption: '' },
      { src: Carousel11, altText: 'Slide 5', caption: '' },
      //{ src: Carousel12, altText: 'Slide 6', caption: '' },
    ];
    const slides = items.map((item, idx) => (
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={idx}>
        <img src={item.src} alt={item.altText} width="100%" height="500px" />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    ));

    return (
      <Container className="homepage">
        <header style={{ textAlign: 'center', marginBottom: '20px', backgroundColor: '#f8f9fa', padding: '20px 0', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h1 style={{ fontSize: '2.5rem', color: '#343a40', margin: '0 0 10px 0', fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}>
            Antares
        </h1>
        <p style={{
            fontSize: '1.2rem',
            color: '#6c757d',
            maxWidth: '600px',
            lineHeight: '1.6',
            margin: 'auto',
            padding: '0 10px'
        }}>
            Transforming Financial Ecosystems - A tool at a time
        </p>
        <div style={{
            position: 'relative',
            paddingBottom: '56.25%', /* 16:9 aspect ratio */
            height: '0',
            overflow: 'hidden'
          }}>
            <video style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%'
            }} autoPlay loop muted>
              <source src={src} type='video/mp4' />
            </video>
          </div>
        </header>
        <section style={{ marginTop: '90px', backgroundColor: '#f8f9fa', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <h2 style={{ fontSize: '2rem', color: '#343a40', fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', marginBottom: '10px' }}>
                About Us
            </h2>
            <p style={{
                fontSize: '1rem',
                color: '#6c757d',
                lineHeight: '1.6',
                maxWidth: '600px',
                margin: 'auto',
                textAlign: 'justify',
                padding: '0 10px'
            }}>
                We are dedicated to transforming financial ecosystems through the power of data, machine learning, and modern technology tools. As a leading innovator in the industry, we believe in harnessing the potential of these cutting-edge advancements to revolutionize how businesses and individuals interact with finance.
            </p>
        </section>
        <Row style={{marginTop: 90}}>
          {this.state.isFlipped.map((isFlipped, index) => (
            <Col xs="4" key={index} className="mb-4">
              <Card onClick={() => this.handleFlip(index)}>
                <CardImg top width="100%" src={[chartjpeg, geojpg, chart3d, pxfuel, t3d_plot, circuit][index]} alt="Card image cap" />
                <CardBody>
                  <CardTitle tag="h5">{this.modulestitle[index] }</CardTitle>
                  <CardText>{this.modulestext[index] }</CardText>
                  {/* <Button onClick={()=>this.handleClick(index)} >Go to {this.modulestitle[index] }</Button> */}
                  <Link to={()=>this.getLink(index)} className="btn btn-secondary" style={{backgroundColor: '#6c757d', bordercolor : '#6c757d' }}>Go to {this.modulestitle[index] }</Link>
                </CardBody>
              </Card>
            </Col>
          ))
          
          }
        </Row>
        {/* <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel> */}
        <footer>
          <p>&copy; 2023 Antares. All rights reserved.</p>
        </footer>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState): any => ({
  btnState: state.homepage,
});

const mapDispatchToProps = {
    setUserAnswer,

};

export const Homepage = connect(mapStateToProps, mapDispatchToProps)(HomepageInner);
