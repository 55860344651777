import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, store } from '../../store'; // Import the AppThunk type if defined
import {getServerUrl, isNumber, OrderFillTransaction, TradeOpen, UserProfile} from '../util'
import axios,{AxiosError, AxiosResponse} from 'axios';
import { AuthState, logOutAction } from '../login/login';
import * as _ from "lodash";


export interface MktDataState {
    data: any[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    page: number;
    tickerOptions: string[];
    fromDate:string;
    toDate:string;
    ticker:string;
    showDropdown:boolean
}

const initialState: MktDataState = {
    data: [],
    status: 'idle',
    error: null,
    page: 0,
    tickerOptions: [],
    fromDate:new Date(Date.now() - 31536000000).toISOString().slice(0, 10),
    toDate:new Date().toISOString().slice(0, 10),
    ticker:'aapl',
    showDropdown:false,
};

const mktdataSlice = createSlice({
    name: 'mktdata',
    initialState,
    reducers: {
        setPageAction: (state, action:PayloadAction<number>) => {
            if(action.payload!==null){
                state.page = action.payload;
            }
        },
        setPendingAction: (state, action:PayloadAction<'idle' | 'loading' | 'succeeded' | 'failed'>) => {
                state.status = action.payload;
        },
        setFetchAction: (state, action:PayloadAction<any[]>) => {
            state.data = action.payload;
            state.status = 'succeeded';
        },
        setFetchRejectedAction: (state, action:PayloadAction<string>) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        setFetchTickerAction: (state, action:PayloadAction<string[]>) => {
            state.tickerOptions = action.payload;
        },
        setFromDateAction: (state, action:PayloadAction<string>) => {
            state.fromDate = action.payload;
        },
        setToDateAction: (state, action:PayloadAction<string>) => {
            state.toDate = action.payload;
        },
        setTickerAction: (state, action:PayloadAction<string>) => {
            state.ticker = action.payload;
        },
        setShowDropdownAction: (state, action:PayloadAction<boolean>) => {
            state.showDropdown = action.payload;
        },
        sortTable: (state,action:PayloadAction<string>) => {
            if(action.payload=='id'){
                //const sortedtable = _.sortBy(state.ordersfilled, o => o.id)
                //state.ordersfilled = sortedtable
            }else if(action.payload=='id'){
                //const sortedtable = _.sortBy(state.ordersfilled, o => o.id)
                //state.ordersfilled = sortedtable
            }
        },
    }
});

export const {setPageAction,  setPendingAction,
    setFetchAction,setFetchRejectedAction,
    setFetchTickerAction,setFromDateAction,
    setToDateAction, setTickerAction,
    setShowDropdownAction,
} = mktdataSlice.actions;


export function getMarketData() {

    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;

        dispatch(setPendingAction('loading'));


        try {

            console.log('state :', state)
            var baseurl = getServerUrl();
            var suburl= "market/fetchmarketdata";
            var body = {'ticker': state.ticker, 'startDate': state.fromDate, 'endDate': state.toDate};

            console.log('body: ', body)
            var userstr =localStorage.getItem("user");
            let user = JSON.parse(userstr??"{}") as UserProfile;
            console.log('user: ', user)

            const data:any =  [{'isgoogleauth':logstate.isgoogleauth}]

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token,
                'isgoogleauth': logstate.isgoogleauth,
            }
            console.log('header',headers,'body',body)
            axios.post(baseurl + suburl,body,{
                headers: headers
            })
                .then(response => {
                    console.log('Score Saved: ',response.data)
                    dispatch(setFetchAction(response.data));
                    dispatch(setPendingAction('succeeded'));
                    return response.data;
                })
                .catch(error => {
                    console.error('Error-1:', error);
                    if(error.response?.status==401){
                        console.log('your token has expired, disconnecting now!')
                        dispatch( logOutAction());
                    }

                    console.log("error.response?.data",error.response?.data)
                    if (error.response?.data) {
                        // Stringify the error response data
                        const errorDataString = JSON.stringify(error.response.data);
                        console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
                        // Check if the string contains the specific substring
                        if (errorDataString.toLowerCase().includes("token is expired")) {
                            console.log("Token is expired error detected.");
                            dispatch(logOutAction());}
                        else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                                console.log("Token invalid error detected.");
                                dispatch(logOutAction());
                        } else {
                            console.log("Different error detected.",errorDataString);
                            // Handle other errors
                        }
                    }

                });


           //return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                console.error('Error-4:', error);

                if (error.response?.status === 401) {
                    console.log('Your token has expired, disconnecting now!');
                    dispatch(logOutAction());
                }

                console.log("error.response?.data",error.response?.data)
                if (error.response?.data) {
                    // Stringify the error response data
                    const errorDataString = JSON.stringify(error.response.data);

                    // Check if the string contains the specific substring
                    if (errorDataString.toLowerCase().includes("token is expired")) {
                        console.log("Token is expired error detected.");
                        dispatch(logOutAction());
                    } else {
                        console.log("Different error detected.",errorDataString);
                        // Handle other errors
                    }
                }


                dispatch(setPendingAction('failed'));
                dispatch(setFetchRejectedAction(error.message));
            }
        }

    };
}


export function fetchTicker(query:string) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;

        dispatch(setPendingAction('loading'));


        try {
            console.log('state :', state)
            var baseurl = getServerUrl();
            var suburl= "market/fetchticker";
            var body = {'ticker': state.ticker};

            console.log('body: ', body)
            var userstr =localStorage.getItem("user");
            let user = JSON.parse(userstr??"{}") as UserProfile;
            console.log('user: ', user)

            const data:any =  [{'isgoogleauth':logstate.isgoogleauth}]

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token,
                'isgoogleauth': logstate.isgoogleauth,
            }
            console.log('header',headers,'body',body)
            axios.post(baseurl + suburl,body,{
                headers: headers
            })
                .then(response => {
                    console.log('Score Saved: ',response.data)
                    dispatch(setFetchTickerAction(response.data));
                    dispatch(setPendingAction('succeeded'));
                    return response.data;
                })
                .catch(error => {
                    console.error('Error:', error);
                    if(error.response?.status==401){
                        console.log('your token has expired, disconnecting now!')
                        dispatch( logOutAction());
                    }
                    console.log("error.response?.data",error.response?.data)
                    if (error.response?.data) {
                        // Stringify the error response data
                        const errorDataString = JSON.stringify(error.response.data);
                        console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
                        // Check if the string contains the specific substring
                        if (errorDataString.toLowerCase().includes("token is expired")) {
                            console.log("Token is expired error detected.");
                            dispatch(logOutAction());}
                        else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                            console.log("Token invalid error detected.");
                            dispatch(logOutAction());
                        } else {
                            console.log("Different error detected.",errorDataString);
                            // Handle other errors
                        }
                    }

                });

        } catch (error)
        {
            if (error instanceof AxiosError) {
                console.error('Error:', error);

                if (error.response?.status === 401) {
                    console.log('Your token has expired, disconnecting now!');
                    dispatch(logOutAction());
                }
                console.log("error.response?.data",error.response?.data)
                if (error.response?.data) {
                    // Stringify the error response data
                    const errorDataString = JSON.stringify(error.response.data);
                    console.log("errorDataString.toLowerCase(): ", errorDataString.toLowerCase())
                    // Check if the string contains the specific substring
                    if (errorDataString.toLowerCase().includes("token is expired")) {
                        console.log("Token is expired error detected.");
                        dispatch(logOutAction());}
                    else if (errorDataString.toLowerCase().includes("token seem invalid")) {
                        console.log("Token invalid error detected.");
                        dispatch(logOutAction());
                    } else {
                        console.log("Different error detected.",errorDataString);
                        // Handle other errors
                    }
                }
                dispatch(setPendingAction('failed'));
                dispatch(setFetchRejectedAction(error.message));
            }
        }

    };
}

export function setPage(page:number) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;
        dispatch(setPageAction(page));
    };
}

export function setFromDate(strdate:string) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        let date = formatDate(strdate)
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;
        console.log(date)
        dispatch(setFromDateAction(date.slice(0, 10)));
    };
}

export function setToDate(strdate:string) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        let date = formatDate(strdate)
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;
        console.log(date)
        dispatch(setToDateAction(date.slice(0, 10)));
    };
}

export function setTicker(ticker:string) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {
        const state = getState().mktdata as MktDataState;
        const logstate = getState().auth as AuthState;
        dispatch(setTickerAction(ticker));
    };
}

//
export function setShowDropdown(show:boolean) {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {

        dispatch(setShowDropdownAction(show));
    };
}

export function resetMktData() {
    //console.warn("==============> fetchTicker begin");
    return async (
        dispatch: (arg0: any) => void,
        getState: () => { (): any; new (): any; mktdata: MktDataState, auth: AuthState }
    ) => {

        dispatch(setFetchAction([]));
    };
}


function formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export default mktdataSlice.reducer;
