import React, { useState, useEffect, useRef } from 'react';
import ChatBubble from './chatBubble';
import './stylesroom.css';
import { connect } from "react-redux";
//import 'react-dropdown/style.css';

import {
  Col,
  Container,
  Row,
} from "reactstrap";

//import "./conversionhub.css";
import Chat from './chat'
import { IChatHubState, initializeConnection, joinChatRoom, setUserChatRoom, 
  setUserName, sendMessage,joinChatRoomInit, joinChatRoom3,initializeConnection2
 } from "../../redux/modules/chathub/chathub";
import { RootState } from "../../redux/store";
import { ConvertState } from "../../redux/modules/convert/convert";
import { AuthState } from "../../redux/modules/login/login";
import WaitingRoom from './chatwaitingroom';
import ChatRoom from './chatRoom';
//import ChatBubble from "./chat";
//import { ConvertState } from "../../redux/modules/convert/convert";
//import { AuthState } from "../../redux/modules/login/login";



interface Message {
  id: number;
  user: string;
  text: string;
}


interface IProps {
  btnState: IChatHubState;
  c_state: ConvertState;
  a_state:AuthState;
}


interface IActions {
  setUserName: typeof setUserName;
  setUserChatRoom: typeof setUserChatRoom;
  joinChatRoom: typeof joinChatRoom;
  initializeConnection: typeof initializeConnection;
  sendMessage: typeof sendMessage;
  joinChatRoomInit: typeof joinChatRoomInit;
  joinChatRoom3: typeof joinChatRoom3;
  initializeConnection2: typeof initializeConnection2;
}



type Props = IActions&IProps;

const ChatHub: React.FC<Props> = (props) => {



  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [user, setUser] = useState<string>('User1');
  const messageId = useRef<number>(1);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      setMessages([...messages, { id: messageId.current++, user, text: inputValue }]);
      setInputValue('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };




  return (
      <>
      <ChatRoom btnState={props.btnState} 
        setUserChatRoom={props.setUserChatRoom} 
        setUserName={props.setUserName} 
        joinChatRoom={props.joinChatRoom}
        initializeConnection={props.initializeConnection}
        sendMessage={props.sendMessage}
        joinChatRoomInit={props.joinChatRoomInit}
        joinChatRoom3={props.joinChatRoom3}
        initializeConnection2={props.initializeConnection2}
        />
      </>


  );
};


const mapStateToProps = (state: RootState): any => ({
  c_state: state.convert,
  a_state: state.auth,
  btnState: state.chathub,
});

const mapDispatchToProps: IActions = {
  setUserName,
  setUserChatRoom,
  joinChatRoom,
  initializeConnection,
  sendMessage,
  joinChatRoomInit,
  joinChatRoom3,
  initializeConnection2,

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHub);





