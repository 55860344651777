// src/features/mentalCalc/mentalCalcSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store'; // Import the AppThunk type if defined
import {getServerUrl, isNumber, UserProfile} from '../util'
import axios,{AxiosError} from 'axios';
import { AuthState, logOutAction } from '../login/login';


export interface HomepageState {
    problem: string;
    answer: string;
    scored: number;
    timerlength_str:string;
    timerlength_int:number;
    isPlaying:boolean;
    answer_str: string;
    answer_int: number;
    allTimeScore: number;
    countdownkey: number;
    modulestitle:any[];
    modulestext:string[];
}

const initialState: HomepageState = {
    problem: '',
    answer: '',
    scored: 0,
    timerlength_str:'',
    timerlength_int:0,
    isPlaying:false,
    answer_str: '',
    answer_int: 0,
    allTimeScore: 0,
    countdownkey:0,
    modulestitle:["Charts","AI Chat","Volatily Map", "Mental Calculation","Market Data","Slice of Life"],
    modulestext:[
        "Explore various trading KPIs and reports. Users can visualize key performance indicators and detailed financial reports, aiding in the analysis of market trends and investment decisions.",
        "Interactive artificial intelligence platform that assists users with specific inquiries about data analysis, providing insights and explanations to help inform decisions across various contexts.",
        "Visually tracks and analyzes market volatility, offering users a comprehensive view of market dynamics. It identifies areas of high fluctuation, aiding traders and analysts in assessing potential risks and opportunities.",
        "Designed to strengthen users' arithmetic skills through structured practice sessions. This tool focuses on improving speed and accuracy in calculations, beneficial for educational purposes and everyday problem-solving.",
        "Delivers real-time financial data, presenting users with the latest market trends, stock prices, and economic indicators. This feature is crucial for investors and analysts needing timely information for strategic decision-making.",
        "A unique compilation of life advice, tips, and reminders gathered from various experiences. This page serves as a patchwork of wisdom, offering practical insights and reflections to enhance everyday living and personal growth."
    
    ],
};

const homepageSlice = createSlice({
    name: 'homepage',
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<string>) => {
            state.answer_str = action.payload;
            state.answer = action.payload;
        },
        updateProblem: (state, action: PayloadAction<string>) => {
            state.problem = action.payload;
            state.answer = '';
            state.answer_str = ''
        },
        settimerlengthSuccess: (state, action:PayloadAction<string>) => {
            if(action.payload==''){
                state.timerlength_int= Number(0)
                state.timerlength_str= ''
            }
            if(isNumber(action.payload)){
                state.timerlength_int= Math.min(Number(action.payload),300)
                if(state.timerlength_int==300){
                    state.timerlength_str= '300'
                }else{
                    state.timerlength_str= action.payload
                }
                
            }
        },
        settimerlengthError: (state,action:PayloadAction<string>) => {
            state.timerlength_str= state.timerlength_str
        },
        scoreAlltimeSucessAction: (state, action:PayloadAction<string>) => {
            if(action.payload==''){
                state.allTimeScore= Number(0)
            }
            if(isNumber(action.payload)){
                state.allTimeScore= Number(action.payload)
            }
        },
        
    }
});

export const { setAnswer, updateProblem,
    settimerlengthSuccess, settimerlengthError
, scoreAlltimeSucessAction } = homepageSlice.actions;

// Thunk action for handling a guess

export const settimerlength = (value: string):  AppThunk => (dispatch) => {
    try {
      console.log(' settimerlength called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(settimerlengthSuccess(value));
    } catch (error) {
      dispatch(settimerlengthError('settimerlength failed '));
    }
  };


  export const setUserAnswer = (value:string):  AppThunk => (dispatch) => {
    try {
      console.log(' setUserAnswer called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setAnswer(value));
    } catch (error) {
        console.log('handleTimerComplete threw an error', error)
      //dispatch(settimerlengthError('settimerlength failed '));
    }
  };
  
export default homepageSlice.reducer;
