import { NavLink, Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import anchor from './../assets/anchor.svg';
import { connect } from 'react-redux';
import fractal from './../assets/fractal.svg';
import React, { useState, useEffect } from 'react';
import { loginUser, googleLogin, authenticateUser, AuthState, setUser, setPassword, setPasswordConfirm, setRegistrationEmail, setIsLogin, SubmitLogin, LogOut } from '../redux/modules/login/login';

interface IProps {
    btnState: AuthState;
}
interface IActions {
    authenticateUser: typeof authenticateUser;
    googleLogin: typeof googleLogin;
    setUser: typeof setUser;
    setPassword: typeof setPassword;
    setPasswordConfirm: typeof setPasswordConfirm;
    setRegistrationEmail: typeof setRegistrationEmail;
    setIsLogin: typeof setIsLogin;
    SubmitLogin: typeof SubmitLogin;
    LogOut: typeof LogOut;
  }

  const navbarStyle = {
    Position: 'sticky',
    top: 0,
    width: '100%',
    backgroundColor: '#334',
    color: 'white',
    padding: '1rem',
    zIndex: 1000, // Ensure it is above other content
  };
  //{{ backgroundColor: '#334', borderBottom: '3px solid #445', padding: '10px 20px', position:'sticky', 'top':'0', zIndex: 1000, }}

const MainNavbarFC: React.FC<IProps & IActions> = (props) => {
    return (
        <Navbar style={{ backgroundColor: '#334', borderBottom: '3px solid #445', padding: '10px 20px', position:'sticky', 'top':'0', zIndex: 1000, }} dark expand="md">
            <NavbarBrand to="/" className="mr-auto" style={{ color: 'lightblue', display: 'flex', alignItems: 'center' }}>
                <img src={anchor} alt="Brand" style={{ marginRight: '10px' }} width="30" height="30" />
                <NavLink to="/" className="nav-link" style={{ color: 'lightblue' }}>
                        Antares
                    </NavLink>
            </NavbarBrand>

            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink to="/mentalcalc" className="nav-link" style={{ color: 'lightblue' }}>
                        MentalCalc
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/market" className="nav-link" style={{ color: 'lightblue' }}>
                        Market Activities
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/mktdata" className="nav-link" style={{ color: 'lightblue' }}>
                        Market Data
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/convert" className="nav-link" style={{ color: 'lightblue' }}>
                        Convertor
                    </NavLink>
                </NavItem>
                <NavItem>
                        <NavLink to="/chathub" className="nav-link" style={{ color: 'lightblue' }}>
                        AI Chat
                    </NavLink>
                </NavItem>

                {props.btnState.isAuthenticated ? (
                    <>
                        <NavItem>
                            <NavLink to="/profile" className="nav-link" style={{ color: 'lightblue' }}>
                                <FontAwesomeIcon icon={faUser} /> {props.btnState.user}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/home" onClick={props.LogOut} className="nav-link" style={{ color: 'lightblue' }}>
                                <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
                            </NavLink>
                        </NavItem>
                    </>
                ) : (
                    <NavItem>
                        <NavLink to="/login" className="nav-link" style={{ color: 'lightblue' }}>
                            Login
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
        </Navbar>
    );
};

export default MainNavbarFC;

//export default MainNavbar;
export const MainNavbar  = connect<IProps, IActions>(
    (state:any) => ({
        btnState: state.auth
    }),
    {
        authenticateUser,
        googleLogin,
        setUser,
        setPassword,
        setPasswordConfirm,
        setRegistrationEmail,
        setIsLogin,
        SubmitLogin,
        LogOut
    }
  )(MainNavbarFC as any);


/*
const MainNavbar: React.FC = () => {
    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/">MyApp</NavbarBrand>
            <Nav className="mr-auto" navbar>
                <NavItem>
                    <NavLink tag={RRNavLink} to="/components/">Components</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RRNavLink} to="/mentalcalc">MentalCalc</NavLink> 
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    };




            <Nav className="ml-auto" navbar>
              {props.btnState.isAuthenticated ? (

                <NavItem style={{ display: 'inline-block' }}>
                  <NavLink href="/mentalcalc" to="/mentalcalc" style={{ color: 'lightgrey' }}>MentalCalc</NavLink> 
                  <NavLink href="#" style={{ color: 'lightgrey' }}>{props.btnState.user}</NavLink>
                  <NavLink href="/" onClick={()=>props.LogOut()} style={{ color: 'lightgrey' ,alignItems:'right'}}>Log Out</NavLink>
                </NavItem>

              ) : (
                <NavItem>
                  <NavLink href="/" style={{ color: 'lightgrey' }}>Login</NavLink>
                </NavItem>
              )}
            </Nav>




<Nav className="ml-auto" navbar style={{ width: '100%', justifyContent: 'space-between' }}>
  {props.btnState.isAuthenticated ? (
    <>
      <div style={{ display: 'flex' }}>
        <NavItem>
          <NavLink href="/mentalcalc" to="/mentalcalc" style={{ color: 'lightgrey' }}>MentalCalc</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" style={{ color: 'lightgrey' }}>{props.btnState.user}</NavLink>
        </NavItem>
      </div>
      <NavItem>
        <NavLink href="/" onClick={() => props.LogOut()} style={{ color: 'lightgrey' }}>Log Out</NavLink>
      </NavItem>
    </>
  ) : (
    // Assuming there's some code for when the user is not authenticated
  )}
</Nav>

              <FontAwesomeIcon icon={faCoffee} /> My App
*/













/*

import { NavLink, Link } from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    //NavLink,
    //Container
  } from 'reactstrap';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faCoffee } from '@fortawesome/free-solid-svg-icons';

  import React, { useState, useEffect } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { loginUser, googleLogin, authenticateUser, AuthState, setUser, setPassword, setPasswordConfirm, setRegistrationEmail, setIsLogin, SubmitLogin, LogOut } from '../redux/modules/login/login';
  import { Button, Form, FormGroup, Label, Input, FormFeedback, Alert, FormText } from 'reactstrap';
  import { GoogleLogin } from 'react-google-login';
  import { RootState } from '../redux/store';
  import { useHistory } from 'react-router-dom';
  
  import { connect } from 'react-redux';
  import anchor from './../assets/anchor.svg';
  import fractal from './../assets/fractal.svg';

  // TypeScript interfaces for component props
  interface IProps {
      btnState: AuthState;
    //authenticateUser: (username: string, password: string, isLogin: boolean) => void;
    //googleLogin: (tokenId: string) => void;
  }
  interface IActions {
      authenticateUser: typeof authenticateUser;
      googleLogin: typeof googleLogin;
      setUser: typeof setUser;
      setPassword: typeof setPassword;
      setPasswordConfirm: typeof setPasswordConfirm;
      setRegistrationEmail: typeof setRegistrationEmail;
      setIsLogin: typeof setIsLogin;
      SubmitLogin: typeof SubmitLogin;
      LogOut: typeof LogOut;
    }
  
  const MainNavbarFC: React.FC<IProps & IActions> = (props) => {
    
    return (
        <>
            <Navbar style={{ backgroundColor: '#333', borderBottom: '3px solid #444', padding: 0, margin: 0 }} dark expand="md">
                <NavbarBrand to="/" style={{ color: 'lightgrey', margin:'0'}}>
                    <img src={anchor} width="30" height="30" alt="" style={{margin:'0'}}/>Antares
                </NavbarBrand>
                
                <Nav className="ml-auto" navbar style={{ width: '100%', justifyContent: 'space-between', padding:'0' }}>
    
                <>
                    <div style={{ display: 'flex' }}>
                        <NavItem>
                            <Link to="/mentalcalc" style={{ color: 'lightgrey' }}>MentalCalc</Link>
                        </NavItem>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <NavItem>
                            <Link to="/" style={{ color: 'lightgrey' }}>{props.btnState.user}</Link>
                        </NavItem>
                        <NavItem>
                            {props.btnState.isAuthenticated ? (
                                <Link to="/" onClick={() => {props.LogOut()}} style={{ color: 'lightgrey' }}>LogOut</Link>
                            ) : (
                                <Link to="/" style={{ color: 'lightgrey' }}>Login</Link>
                            )}
                        </NavItem>
                    </div>
                </>

                </Nav>
            </Navbar>
        </>
    );
  };
*/