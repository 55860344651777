import './modalerror.css'; // You'll create this file for styling
import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

interface ModalProps {
    message: string;
    show: boolean;
    onClose: () => void;
  }
  
  const ModalError: React.FC<ModalProps> = ({ message, show, onClose }) => {

    if (!show) {
        return null;
      }
    
      return (
        <Modal isOpen={show} toggle={onClose}>
          <ModalHeader toggle={onClose}>Error</ModalHeader>
          <ModalBody>
            <p>{message}</p>
            <Button color="secondary" onClick={onClose}>Close</Button>
          </ModalBody>
        </Modal>
      );
};

export default ModalError;
