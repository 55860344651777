import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, googleLogin, authenticateUser, AuthState, setUser, setPassword, setPasswordConfirm, setRegistrationEmail, setIsLogin, SubmitLogin } from '../../redux/modules/login/login';
import { Button, Form, FormGroup, Label, Input, FormFeedback, Alert, FormText } from 'reactstrap';
import { GoogleLogin } from 'react-google-login';
import { RootState } from '../../redux/store';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import {gapi} from 'gapi-script'
import Header from '../header/header';

const clientId= "300744523908-87amq1nhiua7h8k4b3vga2v9795c0vfa.apps.googleusercontent.com"

// TypeScript interfaces for component props
interface IProps {
    btnState: AuthState;
  //authenticateUser: (username: string, password: string, isLogin: boolean) => void;
  //googleLogin: (tokenId: string) => void;
}
interface IActions {
    authenticateUser: typeof authenticateUser;
    googleLogin: typeof googleLogin;
    setUser: typeof setUser;
    setPassword: typeof setPassword;
    setPasswordConfirm: typeof setPasswordConfirm;
    setRegistrationEmail: typeof setRegistrationEmail;
    setIsLogin: typeof setIsLogin;
    SubmitLogin: typeof SubmitLogin;
  }

const LoginFormd: React.FC<IProps & IActions> = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword_hugh] = useState('');
  const [validationError, setValidationError] = useState('');
  const [email, setEmail] = useState('');


  useEffect(()=>{
    function start(){

        gapi.client.init({
            clientId: clientId,
            scope: ""
        })
    };
    gapi.load('client:auth2',start);
  })

  //var accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token // .auth.getToken().accessToken // get logged user access token

  const validateForm = () => {
    if (!username.trim() || !password.trim()) {
      setValidationError('Username and password are required.');
      return false;
    }
    if (password.length < 6) {
      setValidationError('Password must be at least 6 characters long.');
      return false;
    }
    setValidationError('');
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.SubmitLogin()
  };

  const handleGoogleSuccess = (response: any) => {
    console.log('handleGoogleSuccess: response: ', response)
    props.googleLogin(response);
  };

  const handleGoogleFailure = (error: any) => {
    console.error('Google Sign In was unsuccessful: Try Again Later');
  };
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
  };
  return (
    <>
    <Header title="Login/Register" />
    <div style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '5px' }}>
    <Form onSubmit={handleSubmit} style={{width:'75%', margin: 'auto'}}>
      {props.btnState.error && <Alert color="danger">{props.btnState.error}</Alert>}
      {validationError && <Alert color="danger">{validationError}</Alert>}

      {/* Registration Email input */}
      <FormGroup className="position-relative">
        <Label for="email" style={{ color: '#495057', fontWeight: 'bold' }}>Email</Label>
        <Input
          type="email"
          id="email"
          value={props.btnState.email ?? ''}
          onChange={(e) => props.setRegistrationEmail(e.target.value)}
          style={{ backgroundColor: '#fff', borderColor: '#ced4da', color: '#495057' }}
          invalid={!props.btnState.email_valid}
          valid={props.btnState.email_valid}
          required
        />
        {(props.btnState.email_valid !== undefined) && (
            <>
                <FormFeedback tooltip valid style={{fontSize: '12px'}}>
                    Valid email format
                </FormFeedback>
                <FormFeedback tooltip invalid style={{fontSize: '12px'}}>
                    Invalid email format
                </FormFeedback>
            </>
        )}
      </FormGroup>

      {/* Password input */}
      <FormGroup className="position-relative">
        <Label for="password" style={{ color: '#495057', fontWeight: 'bold' }}>Password</Label>
        <Input
          type="password"
          id="password"
          value={props.btnState.pwd ?? ''}
          onChange={(e) => props.setPassword(e.target.value)}
          style={{ backgroundColor: '#fff', borderColor: '#ced4da', color: '#495057' }}
          invalid={!props.btnState.pwd_valid}
          valid={props.btnState.pwd_valid}
          required
        />
        {(props.btnState.pwderror !== undefined) && (
            <>
                <FormFeedback tooltip valid style={{fontSize: '12px'}}>
                    {props.btnState.pwderror}
                </FormFeedback>
                <FormFeedback tooltip invalid style={{fontSize: '12px'}}>
                    {props.btnState.pwderror}
                </FormFeedback>
            </>
        )}
      </FormGroup>

      {/* Confirm Password input */}
      {!props.btnState.islogin && (
        <FormGroup className="position-relative">
          <Label for="passwordConfirm" style={{ color: '#495057', fontWeight: 'bold' }}>Confirm Password</Label>
          <Input
            type="password"
            id="passwordConfirm"
            value={props.btnState.pwd_confirm ?? ''}
            onChange={(e) => props.setPasswordConfirm(e.target.value)}
            style={{ backgroundColor: '#fff', borderColor: '#ced4da', color: '#495057' }}
            invalid={!props.btnState.pwdconfirm_valid}
            valid={props.btnState.pwdconfirm_valid}
            required
          />
          {(props.btnState.pwdconfirm_valid !== undefined) && (
              <>
                  <FormFeedback tooltip valid style={{fontSize: '12px'}}>
                      Passwords match!
                  </FormFeedback>
                  <FormFeedback tooltip invalid style={{fontSize: '12px'}}>
                      Passwords do not match
                  </FormFeedback>
              </>
          )}
        </FormGroup>
      )}

{!props.btnState.islogin && (      
      <FormGroup className="position-relative">
        <Label for="username" style={{ color: '#495057', fontWeight: 'bold' }}>Username</Label>
        <Input
          type="text"
          id="username"
          value={props.btnState.user ?? ''}
          onChange={(e) => props.setUser(e.target.value)}
          style={{ backgroundColor: '#fff', borderColor: '#ced4da', color: '#495057' }}
          invalid={!props.btnState.login_valid}
          valid={props.btnState.login_valid}
          required
        />
        {(props.btnState.loginerror !== undefined) && (
            <>
                <FormFeedback tooltip valid style={{fontSize: '12px'}}>
                    {props.btnState.loginerror}
                </FormFeedback>
                <FormFeedback tooltip invalid style={{fontSize: '12px'}}>
                    {props.btnState.loginerror}
                </FormFeedback>
            </>
        )}
      </FormGroup>
)}
      <div style={{marginTop: '20px'}} />
      <Button type="submit" color="primary" style={{margin: '10px', backgroundColor: '#17a2b8', borderColor: '#17a2b8'}}>
          {props.btnState.islogin ? 'Login' : 'Register'}
      </Button>
      <Button onClick={() => props.setIsLogin()} color="secondary" className="ml-2" style={{margin: '10px', backgroundColor: '#6c757d', borderColor: '#6c757d'}}>
          {props.btnState.islogin ? 'Register' : 'Login'}
      </Button>
      <div style={{marginTop: '20px', marginLeft: '10px'}}>
        <GoogleLogin
          clientId={clientId}
          buttonText="Login with Google"
          onSuccess={(r)=>handleGoogleSuccess(r)}
          onFailure={(r)=>handleGoogleFailure(r)}
          cookiePolicy={"single_host_origin"}
          //isSignedIn={true}
        /> 
      </div>
    </Form>
</div>
</>
  );
};

//        cookiePolicy={'single_host_origin'}

export const LoginForm  = connect<IProps, IActions>(
    (state:any) => ({
        btnState: state.auth
    }),
    {
        authenticateUser,
        googleLogin,
        setUser,
        setPassword,
        setPasswordConfirm,
        setRegistrationEmail,
        setIsLogin,
        SubmitLogin,
    }
  )(LoginFormd as any);


// const mapStateToProps = function(state:any) {
//     return {
//         btnState: state.auth
//     }
//   }
// const mapDispatchToProps = {
//   authenticateUser,
//   googleLogin
// };

// export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
