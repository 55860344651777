// src/index.tsx
import React from 'react';
//import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store,persistor } from './redux/store';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
    
//     <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <App />
//       </PersistGate>
//     </Provider>
    
//   </React.StrictMode>,
//   document.getElementById('root')
// );
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
       <App />
     </PersistGate>
     </Provider>
  </React.StrictMode>
);

reportWebVitals();
