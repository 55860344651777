import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserAnswer, handleGuess, settimerlength, startTimer, handleTimerComplete, getNewProblem, initiateNewProblem, getAllTimeScore,  } from '../../redux/modules/mentalCalc/mentalCalcSlice';
import React, { useState, useRef, useEffect, LegacyRef, useCallback } from 'react';
import { Container, Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {MentalCalcState} from '../../redux/modules/mentalCalc/mentalCalcSlice'
import ScoreDisplay from './score/score';
import { AppThunk } from '../../redux/store'; 
import ReactDOM from 'react-dom';
import Header from '../header/header';

interface IProps {
    rstate: MentalCalcState;
}


interface IActions {
    setUserAnswer: typeof setUserAnswer;
    handleGuess: typeof handleGuess;
    settimerlength: typeof settimerlength;
    startTimer: typeof startTimer;
    handleTimerComplete: typeof handleTimerComplete;
    getNewProblem: typeof getNewProblem;
    getAllTimeScore: typeof getAllTimeScore;

  }

type Props = IActions&IProps;

const MentalCalc: React.FC<Props> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setUserAnswer(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.handleGuess();
    };
    const handleTimerStart = () => {
        props.startTimer()
    };

    const setInputFocus = () => {
        inputRef.current?.focus();
      };

    const dispatch = useDispatch();

    useEffect(() => {
        //alert("initial run");  // Example of using a prop
        //dispatch(initiateNewProblem() );
        props.getAllTimeScore();
      }, [dispatch]);  // Include all dependencies in the array

    
      return (
        <>
        <Header title="Mental Calculus" />
        <Container>
            <Row className="justify-content-center my-3">
                <Col md="10">
                    <Form>
                        <FormGroup row>
                            <Label for="settimer" sm={3} size='lg' style={{ color: '#495057', fontSize: '18px', fontWeight: 'bold' }}>
                                Set duration
                            </Label>
                            <Col sm={9} style={{ display: 'flex' }}>
                                <Input
                                    style={{
                                        backgroundColor: '#fff',
                                        color: '#495057',
                                        borderColor: '#ced4da',
                                        flex: 2,
                                        marginRight: '10px'
                                    }}
                                    id="settimer"
                                    name="settimer"
                                    placeholder="Enter Timer Duration"
                                    type="number"
                                    bsSize='lg'
                                    value={props.rstate.timerlength_str}
                                    onChange={(e) => props.settimerlength(e.target.value)}
                                    disabled={props.rstate.isPlaying}
                                />
                                <Button style={{
                                    backgroundColor: '#17a2b8',
                                    color: '#fff',
                                    borderColor: '#17a2b8',
                                    flex: 1,
                                    
                                }} onClick={() => { handleTimerStart(); setTimeout(setInputFocus, 30); }} 
                                disabled={props.rstate.timerlength_int<=0}
                                >
                                    Start Timer
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            {/* <Row className="justify-content-center align-items-center my-4">
                <Col md="auto" style={{ color: '#495057', fontSize: '24px' }}>
                    <CountdownCircleTimer
                        isPlaying={props.rstate.isPlaying}
                        duration={props.rstate.timerlength_int}
                        colors={['#17a2b8', '#28a745', '#dc3545', '#ffc107']}
                        colorsTime={[7, 5, 2, 0]}
                        key={props.rstate.countdownkey}
                        onComplete={(elapsedtime) => {
                            props.handleTimerComplete(elapsedtime);
                        }}
                    >
                        {({ remainingTime }) => <span>{remainingTime}</span>}
                    </CountdownCircleTimer>
                </Col>
                <Col md="auto" style={{ color: '#495057', fontSize: '24px' }}>
                    <div>Score: {props.rstate.score}</div>
                </Col>
            </Row> */}


            <Row className="justify-content-center align-items-center my-4">
                <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                    <CountdownCircleTimer
                        isPlaying={props.rstate.isPlaying}
                        duration={props.rstate.timerlength_int}
                        colors={['#17a2b8', '#28a745', '#dc3545', '#ffc107']}
                        colorsTime={[7, 5, 2, 0]}
                        key={props.rstate.countdownkey}
                        onComplete={(elapsedtime) => {
                            props.handleTimerComplete(elapsedtime);
                        }}
                    >
                        {({ remainingTime }) => <span style={{ fontSize: '24px', color: '#495057' }}>{remainingTime}</span>}
                    </CountdownCircleTimer>

                    <div style={{
                        backgroundColor: '#f8f9fa', 
                        border: '1px solid #ced4da', 
                        borderRadius: '5px', 
                        marginLeft: '20px', 
                        padding: '10px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', // Align content vertically center
                        alignItems: 'center', // Align content horizontally center
                        width: '150px', // Ensure size is similar to timer for visual balance
                        height: '100px' // Adjust height to match the timer
                    }}>
                        <Label style={{ color: '#495057', fontSize: '18px', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
                            Score
                        </Label>
                        <div style={{
                            color: '#17a2b8', 
                            fontSize: '22px', 
                            fontWeight: 'bold',
                            width: '100%', 
                            textAlign: 'center', // Center the text horizontally
                            marginTop: '10px', // Optional: Adjust spacing based on visual preference
                        }}>
                            {props.rstate.score}
                        </div>
                    </div>
                </Col>
            </Row>

            {props.rstate.isPlaying ? (<Row className="justify-content-center align-items-center my-10">
                <Col md="auto">
                    <Label className="text-center" style={{
                        color: '#495057', fontSize: '24px', fontWeight: 'bold',
                        backgroundColor: '#f8f9fa', border: '1px solid #ced4da', padding: '10px', borderRadius: '5px'
                    }}>
                        <b>{props.rstate.problem}</b>
                    </Label>
                </Col>
            </Row>):<div/>}

            <Row className="justify-content-center align-items-center my-5">
                <Col md="auto">
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                            <Col sm={9} style={{ display: 'flex' }}>
                                <Input
                                    style={{
                                        backgroundColor: '#fff',
                                        color: '#495057',
                                        borderColor: '#ced4da',
                                        flex: 1
                                    }}
                                    id="setanswer"
                                    name="setanswer"
                                    placeholder="Enter Answer"
                                    type="number"
                                    bsSize="lg"
                                    value={props.rstate.answer_str}
                                    onChange={(e) => handleAnswerChange(e)}
                                    innerRef={inputRef}
                                    disabled={!props.rstate.isPlaying}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            <Row className="justify-content-left my-4">
                <Col md="auto">
                    <div style={{ color: '#495057', fontSize: '20px', fontStyle: 'italic' }}>
                        Your All Time Score: {props.rstate.allTimeScore}
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
    
};

const mapStateToProps = (state: RootState): any => ({
    rstate: state.mentalCalc,
});

const mapDispatchToProps: IActions = {
    setUserAnswer,
    handleGuess,
    settimerlength,
    startTimer,
    handleTimerComplete,
    getNewProblem,
    getAllTimeScore,

};

export default connect(mapStateToProps, mapDispatchToProps)(MentalCalc);


