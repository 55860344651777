import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import React, { useState, useCallback } from 'react';
import { Input, Button, Spinner, FormGroup, Label, Container, Row, Col } from 'reactstrap';
import {
    getMarketData,
    fetchTicker,
    MktDataState,
    setPage,
    setFromDate,
    setToDate,
    setTicker, setShowDropdown, setFetchAction,
} from '../../redux/modules/mkt_data/mkt_data'
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import Header from '../header/header';
import debounce from 'lodash/debounce';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './tickerdropdown.css';
import {formatNumber, formatTime, dateToString} from "../../redux/modules/util";

interface IProps {
    rstate:MktDataState;
}

interface IActions {
    getMarketData: typeof getMarketData;
    fetchTicker: typeof fetchTicker;
    setPage: typeof setPage;
    setFromDate: typeof setFromDate;
    setToDate: typeof setToDate;
    setTicker: typeof setTicker;
    setShowDropdown: typeof setShowDropdown;
}

type Props = IActions & IProps;

const MktData: React.FC<Props> = (props) => {

    const dispatch = useDispatch();
    const [rowId, setRowId] = useState(-1);

    const toggleRow = (row:number) => {
        setRowId(row);
    };

    const onRowClick = (state:any, rowInfo:any, column:any, instance:any) => {
        return {
            onClick: (e:any) => {
                console.log('A Td Element was clicked!')
                console.log('it produced this event:', e)
                console.log('It was in this column:', column)
                console.log('It was in this row:', rowInfo)
                console.log('It was in this table instance:', instance)
            }
        }
    }

    const debouncedFetchTickerOptions = useCallback(
        debounce((query: string) => {
            props.fetchTicker(query);
        }, 300),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        props.setTicker(value);
        props.setShowDropdown(true);
        if (value) {
            debouncedFetchTickerOptions(value);
        }
    };

    const handleSubmit = () => {
        if (props.rstate.ticker && props.rstate.fromDate && props.rstate.toDate) {
            props.getMarketData();
        }
    };

    const handleOptionClick = (option:string) => {
        props.setTicker(option);
        props.setShowDropdown(false);
        dispatch(setFetchAction([]));
    };

    return (
        <>
            <Header title="Market Data" />
            <Container fluid style={{ padding: '1px', maxWidth: '90%', margin: '0 auto', marginBottom: '20px' }}>
                <Row>
                    <Col md={{ size: 12 }}>
                        <div style={{ overflowX: 'auto', paddingLeft: '2px', paddingTop: '20px', paddingBottom: '20px', paddingRight: '2px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}>
                            <FormGroup row>
                                <Col sm={2}>
                                    <Label for="ticker">Ticker</Label>
                                    <Input
                                        type="text"
                                        name="ticker"
                                        id="ticker"
                                        value={props.rstate.ticker || ''}
                                        onChange={handleSearchChange}
                                        list="tickerOptions"
                                        style={{ maxWidth: '10ch' }}
                                    />
                                    {props.rstate.showDropdown && (
                                        <ul className="ticker-dropdown" style={{ maxWidth: '10ch' }}>
                                            {props.rstate.tickerOptions.map((option, index) => (
                                                <li key={index} onClick={() => handleOptionClick(option)}>
                                                    {option}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Col>
                                <Col sm={2}>
                                    <Label for="fromDate">From Date</Label>
                                    <DatePicker
                                        id="fromDate"
                                        selected={new Date(props.rstate.fromDate)}
                                        onChange={(date) => props.setFromDate(dateToString(date))}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Label for="toDate">To Date</Label>
                                    <DatePicker
                                        id="toDate"
                                        selected={new Date(props.rstate.toDate)}
                                        onChange={(date) => props.setToDate(dateToString(date))}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Col>
                                <Col sm={2} className="d-flex align-items-end">
                                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                                </Col>
                            </FormGroup>
                            {props.rstate.status === 'loading' ? (
                                <Spinner />
                            ) : (
                                <ReactTable
                                    data={props.rstate.data}
                                    columns={[
                                        { Header: 'Date', accessor: 'date', style: { textAlign: 'right' }, Cell: ({ value }) => formatTime(value ?? '') },
                                        { Header: 'Open', accessor: 'open', style: { textAlign: 'right' }, Cell: ({ value }) => formatNumber(value ?? 0) },
                                        { Header: 'High', accessor: 'high', style: { textAlign: 'right' }, Cell: ({ value }) => formatNumber(value ?? 0) },
                                        { Header: 'Low', accessor: 'low', style: { textAlign: 'right' }, Cell: ({ value }) => formatNumber(value ?? 0) },
                                        { Header: 'Close', accessor: 'close', style: { textAlign: 'right' }, Cell: ({ value }) => formatNumber(value ?? 0) },
                                        { Header: 'Volume', accessor: 'volume', style: { textAlign: 'right' }, Cell: ({ value }) => formatNumber(value ?? 0) }
                                    ]}
                                    defaultPageSize={25}
                                    showPagination={true}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const mapStateToProps = (state: RootState): any => ({
    rstate: state.mktdata,
});

const mapDispatchToProps: IActions = {
    getMarketData,
    fetchTicker,
    setPage,
    setFromDate,
    setToDate,
    setTicker,
    setShowDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(MktData);
