// src/App.tsx
import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggle } from './redux/modules/modal/modalSlice';
import {MainNavbar} from './components/Navbar';
import MainModal from './components/MainModal';
import Modaltest  from '../src/components/modaltest/modaltest';
import MentalCalc from '../src/components/mentalcalc/mentalcalc';
import { RootState } from './redux/store';
import {LoginForm} from './components/authform/authform'
import { logOutAction } from './redux/modules/login/login';
import UserComp from './components/usercomp/usercomp';
import { Homepage } from './components/homepage/homepage';
import OandaOrderFillTable from './components/oanda/oanda_orders';
import ConvertComp from './components/convert/convert';
import ChatHub from './components/chathub/chathub';
import MktData from "./components/mktdata/mktdata";

const App: React.FC = () => {
  const modalIsOpen = useSelector((state: any) => state.modal.isOpen);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const username = useSelector((state: RootState) => state.auth.user);


  const handleToggle = () => {
    dispatch(toggle());
  };

  const handleLogOut = () => {
    console.log('handleLogOut called')
    dispatch(logOutAction());
  };
  const contentStyle = {
    marginTop: '0', //'4rem', // Adjust based on navbar height
    height: '100%', //'calc(100vh - 4rem)', // Adjust based on navbar height
    OverflowY: 'auto', // Make content scrollable
    padding: '1rem',
  };

  return (
        <Router>
        <div>
            {/* <MainNavbar /> */}
            <MainNavbar />
            <div style={contentStyle}>
              <Switch>

                  
                  {/* <Route path="/home" component={isAuthenticated ?Homepage:LoginForm} /> */}
                  <Route path="/home" component={isAuthenticated ?Homepage:LoginForm} />
                  <Route path="/profile" component={isAuthenticated ?UserComp:LoginForm} />
                  <Route path="/components" component={MainModal} />
                  {/* <Route path="/mentalcalc" component={isAuthenticated ?MentalCalc:LoginForm} /> */}
                  <Route path="/mentalcalc" component={isAuthenticated ?MentalCalc:LoginForm} />
                  <Route path="/market" component={isAuthenticated ?OandaOrderFillTable:LoginForm} />
                  {/* <Route path="/convert" component={isAuthenticated ?FileConverter:LoginForm} /> */}
                  <Route path="/convert" component={isAuthenticated ?ConvertComp:LoginForm} />
                  <Route path="/chathub" component={isAuthenticated ?ChatHub:LoginForm} />
                  <Route path="/mktdata" component={isAuthenticated ?MktData:LoginForm} />
                  <Route path="/login" component={isAuthenticated ?UserComp:LoginForm} />

                  {/* <Route exact path="/" component={isAuthenticated ?Homepage:LoginForm} /> */}
                  <Route exact path="/" component={isAuthenticated ?Homepage:LoginForm} />

              </Switch>
            </div>
        </div>
    </Router>

  );
};

export default App;

/**
 * 
 * {isAuthenticated ? <Redirect to="/home" /> : <LoginForm />}
 * 
 */