// src/features/auth/authSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios,{AxiosError} from 'axios';
import { RootState, AppDispatch, AppThunk, store } from '../../store';
import { format } from 'path';
import { stringify } from 'querystring';
import { Req_Response, User, UserProfile, UserQuery, getServerUrl, handleErrors, logoutUser } from '../util';
import { push } from 'react-router-redux'
//const https = require("https");
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export interface AuthState {
  isAuthenticated: boolean;
  user: string|null;
  token: string | null;
  error: string | null;
  loginerror: string | null;
  login_valid:boolean|undefined;
  pwderror: string | null;
  pwd_valid:boolean|undefined;
  pwd: string|null;
  pwd_confirm: string|null;
  pwdconfirm_valid: boolean|undefined;
  email: string|null;
  email_valid:boolean|undefined;
  loading:boolean|undefined;
  fetchfailerror: string|null;
  islogin:boolean;
  usertoken: string|null;
  isgoogleauth:boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  error: null,
  loginerror:null,
  login_valid:undefined,
  pwderror:null,
  pwd_valid:undefined,
  pwd:null,
  pwd_confirm:null,
  pwdconfirm_valid:undefined,
  email:null,
  email_valid:undefined,
  loading:undefined,
  fetchfailerror: null,
  islogin:true,
  usertoken:null,
  isgoogleauth:false,

};

var userexcludedformat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateSuccess: (state, action: PayloadAction<string>) => {
        state.isAuthenticated = true;
        state.token = action.payload;
        state.error = null;
      },
      authenticateFailure: (state, action: PayloadAction<string>) => {
        state.isAuthenticated = false;
        state.error = action.payload;
      },
    setCredentials: (state, action: PayloadAction<{ user: any; token: string }>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
    setuserSuccess: (state, action: PayloadAction<string>) => {
        state.user = action.payload;
        if(action.payload.length==0){
            state.loginerror=null;
            state.login_valid=undefined;
        }else if(userexcludedformat.test(action.payload)){
          state.loginerror="login does not accept special characters"
          state.login_valid=false
        }else {
            state.loginerror='login is acceptable';
            state.login_valid=true;
        }
      },
      setuserPasswordSuccess: (state, action: PayloadAction<string>) => {
        state.pwd = action.payload;

        var strength = 0;
        if (action.payload.match(/[a-z]+/)) {
          strength += 1;
        }
        if (action.payload.match(/[A-Z]+/)) {
          strength += 1;
        }
        if (action.payload.match(/[0-9]+/)) {
          strength += 1;
        }
        if (action.payload.match(/[$@#&!]+/)) {
          strength += 1;
        }
        if (action.payload.length > 6) {
            strength += 1;
        }

        if(strength==0){
            state.pwderror=null;
            state.pwd_valid=undefined;
        }else if(strength<5){
          state.pwderror="Password should content upper, lowercase, numbers, special characters and longer than 6 characters"
          state.pwd_valid=false
        }else {
            state.pwderror='password is acceptable';
            state.pwd_valid=true;
        }
      },
      setuserPasswordConfirmSuccess: (state, action: PayloadAction<string>) => {
        state.pwd_confirm = action.payload;
        if(action.payload.length==0){
            state.pwdconfirm_valid=undefined;
        }else {
          state.pwdconfirm_valid=(state.pwd==state.pwd_confirm)
        }
      },
      setRegistrationEmailSuccess: (state, action: PayloadAction<string>) => {
        state.email = action.payload;
        if(action.payload.length==0){
            state.email_valid=undefined;
        }else if(validateEmail(action.payload)){
            state.email_valid=true;
        }else {
            state.email_valid=false;
        }
      },
      fetchBeginAction: (state, action: PayloadAction<string>) => {
        state.loading = true;
      },
      loginSucessAction: (state, action: PayloadAction<User>) => {
        state.user = action.payload.username;
        state.loading = false;
        state.isAuthenticated = true;
        state.usertoken= action.payload.token;

      },
      loginFailAction: (state,action: PayloadAction<string>) => {
        state.user = '';
        state.loading = false;
        state.isAuthenticated = false;
        state.usertoken= null;

      },
      fetchFailAction: (state, action: PayloadAction<string>) => {
        state.fetchfailerror = action.payload;
        state.loading = false;
        state.isAuthenticated = false;
      },
      setIsLoginSuccess: (state, ) => {
        state.islogin = !state.islogin;
      },
      setIsLoginGoogleAuthSuccess: (state, action: PayloadAction<boolean>) => {
        state.isgoogleauth = action.payload;
      },
      logOutAction: (state) => {
        state.isAuthenticated =  false;
        state.user =  null;
        state.token =  null;
        state.error =  null;
        state.loginerror = null;
        state.login_valid = undefined;
        state.pwderror = null;
        state.pwd_valid = undefined;
        state.pwd = null;
        state.pwd_confirm = null;
        state.pwdconfirm_valid = undefined;
        state.email = null;
        state.email_valid = undefined;
        state.loading = undefined;
        state.fetchfailerror =  null;
        state.islogin = true;
        state.usertoken = null


      },
  }
});

// Actions
export const { authenticateSuccess, authenticateFailure, setCredentials, 
    setError, logout, setuserSuccess,setuserPasswordSuccess,
    setuserPasswordConfirmSuccess, setRegistrationEmailSuccess,
    fetchBeginAction,loginSucessAction,fetchFailAction, setIsLoginSuccess,
    loginFailAction, logOutAction, setIsLoginGoogleAuthSuccess } = authSlice.actions;


// Thunk example for login
export const loginUser = (username: string, password: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('http://yourapi/login', { username, password });
    dispatch(setCredentials({ user: response.data.user, token: response.data.token }));
  } catch (error) {
    dispatch(setError('Login failed: Invalid username or password'));
  }
};

// Thunks
export const authenticateUser = (username: string, password: string, isLogin: boolean): AppThunk => async dispatch => {
    try {
      const url = `http://yourapi/${isLogin ? 'login' : 'register'}`;
      const response = await axios.post(url, { username, password });
      localStorage.setItem('token', response.data.token);
      dispatch(authenticateSuccess(response.data.token));
    } catch (error) {
      dispatch(authenticateFailure('Authentication failed: Invalid credentials'));
    }
  };
  
  export const googleLogin = (googleres: any): AppThunk => async dispatch => {
    try {
      console.log('googleLogin reducer: ',googleres);
      var user = {'email':googleres?.profileObj.email,
      'username':googleres?.profileObj.name,  
      'roles':googleres?.xc?.scope,
      'token':googleres?.tokenObj.id_token,
    
    } as User
      var tokenId: string='';
      //const response = await axios.post('http://yourapi/google-login', { tokenId });
      localStorage.setItem('token', googleres?.tokenObj?.access_token);
      let strorage_user= new UserProfile(user.email,user.token, user.username)
      localStorage.setItem("user", JSON.stringify(strorage_user));
      dispatch(loginSucessAction(user));
      dispatch(setIsLoginGoogleAuthSuccess(true));
    } catch (error:any) {
        console.error('Error:', error);
        dispatch( loginFailAction(error?.message));
        localStorage.setItem("user", '');
    }
  };
  
  export const setUser = (username: string) => async (dispatch: AppDispatch) => {
    try {
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setuserSuccess(username));
    } catch (error) {
      dispatch(setError('Setting user failed: Invalid username '));
    }
  };
  export const setPassword = (username: string) => async (dispatch: AppDispatch) => {
    try {
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setuserPasswordSuccess(username));
    } catch (error) {
      dispatch(setError('Setting pwd failed '));
    }
  };
  export const setPasswordConfirm = (username: string) => async (dispatch: AppDispatch) => {
    try {
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setuserPasswordConfirmSuccess(username));
    } catch (error) {
      dispatch(setError('Setting pwd confirm failed '));
    }
  };
  export const setRegistrationEmail = (email: string) => async (dispatch: AppDispatch) => {
    try {
      console.log('set setRegistrationEmail called')
      //const response = await axios.post('http://yourapi/login', { username, password });
      dispatch(setRegistrationEmailSuccess(email));
    } catch (error) {
      dispatch(setError('Setting registration mail failed '));
    }
  };
  export const setIsLogin = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoginSuccess());
    } catch (error) {
      dispatch(setError('Switching Registration Login failed '));
    }
  };


  const validateEmail = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  //const httpsAgent = new https.Agent({
  //  rejectUnauthorized: false // This will ignore the SSL certificate errors
  //});

  export function LogOut() {
    console.warn("==============> LogOut Called");
    return (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; auth: AuthState }
    ) => {
      const state = getState().auth as AuthState;
      console.log('LogOut called: ',getState(),getState().auth)
      localStorage.setItem("user", '');
      dispatch( logOutAction());
      //dispatch(logoutUser());

    };
  }

  export function SubmitLogin() {
    // console.warn("==============> SubmitOvertime begin");
    return (
      dispatch: (arg0: any) => void,
      getState: () => { (): any; new (): any; auth: AuthState }
    ) => {
      const state = getState().auth as AuthState;
      console.log('getState().login: ',getState(),getState().auth)

      dispatch( fetchBeginAction(''));
      // console.warn("fetch start", state.email, moment().format("LLLL"));
      var baseurl = getServerUrl();
      var suburl= (state.islogin)?"Auth/login":"Auth/register";
      var body = (state.islogin)?new UserQuery(state.email??'',state.pwd??''):{'username':state.user??'','password': state.pwd??'','email': state.email??''}
      
      console.log('body: ', body)

      /*
      fetch('http://localhost:3001/api/Auth/register')
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));

      */


    axios.post(baseurl + suburl,body)
        .then(response => {
            console.log('login register response: ',response.data)
            localStorage.setItem("user", JSON.stringify(response.data));
            dispatch( loginSucessAction(response.data));
            dispatch(setIsLoginGoogleAuthSuccess(false));
            //store.dispatch(push('/your-route'))
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            dispatch( loginFailAction(error.message));
            localStorage.setItem("user", '');

        });

    //  axios.post(baseurl + suburl,body, { httpsAgent })
    //    .then(response => console.log(response.data))
    //    .catch(error => console.error('Error:', error));
      
/*
      return fetch(baseurl + suburl, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
          'rejectUnauthorized': 'false'
        }),
        body: JSON.stringify(body),
      })
        .then(handleErrors)
        .then((res) => res.json() as Req_Response)
        .then((json) => {
          console.warn("fectching over", json);
          if (json.result.token) {
            console.log('kjbfjbfvbsfjbfsn===>',JSON.stringify(json.result))
            localStorage.setItem("user", JSON.stringify(json.result));
            dispatch( loginSucessAction(json.result));
            return json.result;
          }
  
        })
        .catch((error) => {
          // // console.warn(
          //   "Error submitOvertime -+- while fetching :",
          //   "-" + state.email + "-",
          //   error
          // );
          dispatch( fetchFailAction(error));
        });
        */
    };
  }

export default authSlice.reducer;
